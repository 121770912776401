import getFiat from "./getFiat";
import postFiat from "./postFiat";
import putFiat from "./putFiat";
import deleteFiat from "./deleteFiat";

export default {
  getFiat,
  postFiat,
  putFiat,
  deleteFiat,
};
