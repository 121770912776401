import { requestAsync } from '@digitalwing.co/redux-query-immutable';
import endpoints from 'api/endpoints';

/**
 *
 * @typedef PostMessage
 * @property files {Array}
 * @property body {String}
 *
 * @param id {String}
 * @param {PostMessage} requestBody
 * @param successCallback {Function}
 */
export default ({ id, requestBody, successCallback }) => requestAsync({
  url: endpoints.getMessageUrl(id),
  queryKey: `post${endpoints.getMessageUrl(id)}`,
  body: requestBody,
  meta: {
    authToken: true,
    successCallback,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
  },
});
