import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { user } from "schemas";
import Immutable from "immutable";

export default (requestBody) => ({
  url: endpoints.getUserBlock(),
  queryKey: endpoints.getUserBlock(),
  transform: (response) => normalize(response.data, user.schema).entities,
  body: requestBody,
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    user: (prevUser = Immutable.Map(), nextUser) =>
      prevUser.mergeDeep(nextUser),
  },
});
