export default [
  {
    id: "ru",
    title: "Russia",
  },
  {
    id: "ua",
    title: "Ukraine",
  },
  {
    id: "it",
    title: "Italy",
  },
  {
    id: "be",
    title: "Belgium",
  },
  {
    id: "gr",
    title: "Greece",
  },
  {
    id: "kz",
    title: "Kazakhstan",
  },
  {
    id: "pl",
    title: "Poland",
  },
  {
    id: "cz",
    title: "Czechia",
  },
  {
    id: "lv",
    title: "Latvia",
  },
  {
    id: "lt",
    title: "Lithuania",
  },
  {
    id: "ee",
    title: "Estonia",
  },
  {
    id: "am",
    title: "Armenia",
  },
  {
    id: "az",
    title: "Azerbaijan",
  },
  {
    id: "fr",
    title: "France",
  },
  {
    id: "nl",
    title: "Netherlands",
  },
  {
    id: "at",
    title: "Austria",
  },
  {
    id: "ch",
    title: "Switzerland",
  },
  {
    id: "by",
    title: "Belarus",
  },
  {
    id: "ge",
    title: "Georgia",
  },
  {
    id: "ve",
    title: "Venezuela",
  },
  {
    id: "cn",
    title: "China",
  },
  {
    id: "in",
    title: "India",
  },
  {
    id: "ca",
    title: "Canada",
  },
  {
    id: "es",
    title: "Spain",
  },
  {
    id: "jp",
    title: "Japan",
  },
  {
    id: "gb",
    title: "United Kingdom",
  },
  {
    id: "de",
    title: "Germany",
  },
  {
    id: "pt",
    title: "Portugal",
  },
  {
    id: "se",
    title: "Sweden",
  },
  {
    id: "dk",
    title: "Denmark",
  },
  {
    id: "ie",
    title: "Ireland",
  },
  {
    id: "hu",
    title: "Hungary",
  },
];
