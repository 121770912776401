import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  title: {
    display: "inline",
  },
  buttons: {
    marginLeft: "auto",
  },
  row: {
    transition: "background 0.1s ease",
    "&:hover": {
      background: "#c3c3c3",
    },
  },
});

function OfferInfoBlock({ offer, stopOffer, deleteOffer, startOffer }) {
  const classes = useStyles();

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <h4 className={classes.title}>Информация по объявлению: </h4>
        <div>
          {!offer.get("isDeleted") &&
            (offer.get("isActive") ? (
              <Tooltip title="Приостановить объявление" placement="top">
                <IconButton
                  aria-label="pause"
                  onClick={() => {
                    stopOffer(offer.get("id"));
                  }}
                >
                  <PauseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Возобновить объявление" placement="top">
                <IconButton
                  aria-label="start"
                  onClick={() => {
                    startOffer(offer.get("id"));
                  }}
                >
                  <PlayArrowIcon />
                </IconButton>
              </Tooltip>
            ))}
          {!offer.get("isDeleted") && (
            <Tooltip title="Удалить объявление" placement="top">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  deleteOffer(offer.get("id"));
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Box>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Статус объявления:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {offer.get("isDeleted")
                ? "удалено"
                : offer.get("isActive")
                ? "активно"
                : "приостановлено"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Направление:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {offer.get("sellCurrencyAlias")}/{offer.get("buyCurrencyAlias")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Продавец, username:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">{offer.get("ownerUsername")}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Кошелек отправки (тип/id):
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {offer.get("sellPaymentID")
                ? offer.get("sellPaymentType") +
                  "/" +
                  offer.get("sellPaymentID")
                : offer.get("sellPaymentType")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Кошелек получения (тип/id):
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {offer.get("buyPaymentID")
                ? offer.get("buyPaymentType") +
                  "/" +
                  offer.get("buyPaymentID")
                : offer.get("buyPaymentType")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Курс:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {offer.get("rate")} (
              {offer.get("customPrice")
                ? "собственный"
                : offer.getIn(["exchangeRate", "exchangeAlias"])}
              )
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Маржа:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">{offer.getIn(["exchangeRate", "profitPercent"])}%</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Лимиты:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {offer.get("minimumAmount")} - {offer.get("maximumAmount")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6} className={classes.label}>
            Условия:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {offer.get("description")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

OfferInfoBlock.propTypes = {
  offer: PropTypes.object.isRequired,
};

export default OfferInfoBlock;
