import React from "react";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";
import { forms, apiHOCs } from "components";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const defaultBank = {
  alias: "",
  shortName: "",
  fullName: "",
  englishName: "",
  colorBg: "#000000",
  color: "#000000",
  icon: "",
  fiats: [],
  countries: [],
};

const BankScreen = ({
  modal,
  setModal,
  banks,
  fiat,
  postBank,
  putBank,
  initBank,
  setInitBank,
  selected,
  setSelected,
  changeStatusBank,
  deleteBank,
  getBanks,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: "center",
        }}
      >
        Bank
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {banks.map((bank) => (
          <ListItem
            key={bank.get("id")}
            button
            onClick={() => {
              setInitBank({
                alias: bank.get("alias"),
                shortName: bank.get("shortName"),
                fullName: bank.get("fullName"),
                englishName: bank.get("englishName"),
                colorBg: bank.get("colorBg"),
                color: bank.get("color"),
                icon: bank.get("icon"),
                fiats: bank.get("fiats").split(","),
                countries: bank.get("countries").split(","),
              });
              setSelected(bank.get("id"));
              setModal(true);
            }}
          >
            <ListItemText
              primary={bank.get("alias")}
              secondary={bank.get("fullName")}
            />
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                changeStatusBank(bank.get("id"));
              }}
              color={bank.get("isActive") ? "secondary" : "primary"}
            >
              {bank.get("isActive") ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
            <IconButton
              style={{ marginLeft: 20 }}
              onClick={(e) => {
                e.stopPropagation();
                deleteBank(bank.get("id")).then(() => {
                  getBanks();
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
    >
      <Paper
        elevation={1}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          height: 500,
          overflow: "auto",
          padding: 20,
        }}
      >
        {modal && (
          <forms.BankForm
            tags={fiat.toJS().map((f) => ({ id: f.alias, title: f.alias }))}
            onSubmit={(body) =>
              selected === ""
                ? postBank({
                    ...body.toJS(),
                    fiats: body.toJS().fiats.join(","),
                    countries: body.toJS().countries.join(","),
                  }).then((resp) => {
                    if (!resp.body.errorCode) {
                      setModal(false);
                    } else {
                      const err = JSON.stringify(resp.body.data);
                      alert(`postBank error: ${err}`);
                      console.log(err);
                    }
                  })
                : putBank({
                    ...body.toJS(),
                    bankId: selected,
                    fiats: body.toJS().fiats.join(","),
                    countries: body.toJS().countries.join(","),
                  }).then((resp) => {
                    if (!resp.body.errorCode) {
                      setModal(false);
                    } else {
                      const err = JSON.stringify(resp.body.data);
                      alert(`putBank error: ${err}`);
                      console.log(err);
                    }
                  })
            }
            handleClose={() => {
              setModal(false);
              setInitBank({ ...defaultBank });
              setSelected("");
            }}
            type={selected === "" ? "create" : "save"}
            initialValues={initBank}
          />
        )}
      </Paper>
    </Modal>
  </div>
);

BankScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  banks: PropTypes.object.isRequired,
  fiat: PropTypes.object.isRequired,
  postBank: PropTypes.func.isRequired,
  putBank: PropTypes.func.isRequired,
  changeStatusBank: PropTypes.func.isRequired,
  initBank: PropTypes.object.isRequired,
  setInitBank: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  getBanks: PropTypes.func.isRequired,
};

export default compose(
  withState("modal", "setModal", false),
  withState("initBank", "setInitBank", { ...defaultBank }),
  withState("selected", "setSelected", ""),
  apiHOCs.BankApiHOC(),
  apiHOCs.FiatApiHOC()
)(BankScreen);
