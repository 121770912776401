import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form/immutable";
import { ui } from "components";
import Button from "@material-ui/core/Button";

import "./style.scss";

const TechnicalBreakForm = ({ handleSubmit, status }) => (
  <form className="technical-break-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="reason"
      props={{
        id: "reason",
        label: "Reason",
        fullWidth: true,
        multiline: true,
        disabled:
          status === "error" || status === "loading" || status === "started",
        style: {
          marginBottom: 10,
        },
      }}
    />
    <Field
      component={ui.Fields.DateTimeField}
      name="endTime"
      props={{
        id: "endTime",
        label: "End time",
        disabled:
          status === "error" || status === "loading" || status === "started",
        style: {
          marginBottom: 10,
        },
      }}
    />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      disabled={status === "loading" || status === "error"}
      color={status === "started" ? "secondary" : "primary"}
      style={{
        marginTop: 20,
      }}
    >
      {status === "started" ? "STOP" : "START"}
    </Button>
  </form>
);

TechnicalBreakForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default compose(
  reduxForm({
    form: "authForm",
  })
)(TechnicalBreakForm);
