import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { fiat as fiatSchemas } from "schemas";

export default ({ resultKey = "fiat" } = { resultKey: "fiat" }) => ({
  url: endpoints.getFiatUrl(),
  transform: (response) =>
    normalize(response.data, fiatSchemas.arrayOfFiatSchemas).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, fiatSchemas.arrayOfFiatSchemas)
      .result,
  }),
  queryKey: endpoints.getFiatUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    fiat: (prevFiat = Immutable.Map(), fiat) => prevFiat.mergeDeep(fiat),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => result,
  },
});
