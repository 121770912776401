import endpoints from "api/endpoints";

export default ({ id, isCancel }) => ({
  url: endpoints.getPutSuspectCancel(id, isCancel),
  queryKey: endpoints.getPutSuspectCancel(id, isCancel),
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
});
