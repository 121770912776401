import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { ticket } from "schemas";
import Immutable from "immutable";

export default (body) => ({
  url: endpoints.getTicketsUrl(body),
  queryKey: endpoints.getTicketsUrl(),
  transform: (response) =>
    normalize(response.data, ticket.schemasArray).entities,
  transformResult: (response) => ({
    tickets: normalize(response.data, ticket.schemasArray).result,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  },
  update: {
    ticket: (prevEntities = Immutable.Map(), entities) =>
      prevEntities.mergeDeep(entities),
  },
  updateResult: {
    tickets: (prevResult, result) => result,
  },
});
