import { bindActionCreators } from "redux";
import { compose } from "recompose";

import {
  requestAsync,
  updateResults,
} from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { offer } from "api";

import { getOffer } from "./selectors";

const OfferApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        offer: getOffer(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getOffer: (requestBody) =>
              requestAsync(offer.getOffer(requestBody)),
            resetOffer: () => updateResults({ offer: {} }),
            stopOffer: (requestBody) =>
              requestAsync(offer.stopOffer(requestBody)),
            startOffer: (requestBody) =>
              requestAsync(offer.startOffer(requestBody)),
            deleteOffer: (requestBody) =>
              requestAsync(offer.deleteOffer(requestBody)),
          },
          dispatch
        ),
      })
    )
  )(WrappedComponent);

export default OfferApiHOC;
