import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { deal, timer } from "schemas";

export const getDeal = createSelector(
  (state) => getResults(state).get("deal", Immutable.Map()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, deal.dealSchema, entities)
);

export const getTimers = createSelector(
  (state) => getResults(state).get("dealTimers", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, timer.arrayOfTimerSchemas, entities)
);
