import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import endpoints from "api/endpoints";

export default () =>
  requestAsync({
    url: endpoints.getProfitLimitsUrl(),
    queryKey: endpoints.getProfitLimitsUrl(),
    transformResult: (response) => ({
      profitLimits: response.data,
    }),
    meta: {
      authToken: true,
    },
    options: {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    },
    updateResult: {
      profitLimits: (_, result) => result,
    },
  });
