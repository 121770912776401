import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { commission } from "schemas";

export default (
  {
    resultKey = "commission",
    tickerBuy,
    tickerSell,
    commissionId,
    commissionPercentSell,
    commissionPercentBuy,
  } = { resultKey: "commission" }
) => ({
  url: endpoints.getActionCommissionUrl(commissionId),
  transform: (response) =>
    normalize(response.data, commission.commissionSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, commission.commissionSchema).result,
  }),
  queryKey: endpoints.getActionCommissionUrl(commissionId),
  meta: {
    authToken: true,
  },
  body: {
    tickerBuy,
    tickerSell,
    commissionPercentSell: parseFloat(commissionPercentSell),
    commissionPercentBuy: parseFloat(commissionPercentBuy),
    symbol: `${tickerSell}_${tickerBuy}`,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    commission: (prevCommission = Immutable.Map(), result) =>
      prevCommission.mergeDeep(result),
  },
});
