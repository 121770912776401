import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form/immutable";
import { greaterThanZero, required } from "validators";
import { ui } from "components";
import Button from "@material-ui/core/Button";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

const renderCheckbox = ({ input, label }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  </div>
);

const CryptoForm = ({ handleSubmit, type, handleClose }) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="alias"
      props={{
        id: "alias",
        label: "alias",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="shortName"
      props={{
        id: "shortName",
        label: "Short Name",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="fullName"
      props={{
        id: "fullName",
        label: "Full Name",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="priority"
      props={{
        id: "priority",
        label: "Priority",
        type: "number",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="ticker"
      props={{
        id: "ticker",
        label: "ticker",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.ColorField}
      name="colorBg"
      props={{
        id: "colorBg",
        label: "Background Color",
      }}
    />
    <Field
      component={ui.Fields.ColorField}
      name="color"
      props={{
        id: "color",
        label: "Color",
      }}
    />

    <div className="auth-form__row">
      <span className="auth-form__row__label">crypto:</span>
      <Field
        component={ui.Fields.BaseField}
        name="minLimitCrypto"
        props={{
          id: "minLimitCrypto",
          label: "min Limit",
          type: "number",
          fullWidth: true,
        }}
        styleWrapper={{ marginLeft: 10 }}
      />
      <Field
        component={ui.Fields.BaseField}
        name="maxLimitCrypto"
        props={{
          id: "maxLimitCrypto",
          label: "max Limit",
          type: "number",
          fullWidth: true,
        }}
        validate={[greaterThanZero]}
        styleWrapper={{ marginLeft: 10 }}
      />
    </div>
    {/*<div className="auth-form__row">*/}
    {/*  <span className="auth-form__row__label">*/}
    {/*    fiat:*/}
    {/*  </span>*/}
    {/*  <Field*/}
    {/*    component={ui.Fields.BaseField}*/}
    {/*    name="minLimitFiat"*/}
    {/*    props={{*/}
    {/*      id: 'minLimitCrypto',*/}
    {/*      label: 'min Limit',*/}
    {/*      type: 'number',*/}
    {/*      fullWidth: true,*/}
    {/*    }}*/}
    {/*    styleWrapper={{ marginLeft: 10 }}*/}
    {/*  />*/}
    {/*  <Field*/}
    {/*    component={ui.Fields.BaseField}*/}
    {/*    name="maxLimitFiat"*/}
    {/*    props={{*/}
    {/*      id: 'maxLimitCrypto',*/}
    {/*      label: 'max Limit',*/}
    {/*      type: 'number',*/}
    {/*      fullWidth: true,*/}
    {/*    }}*/}
    {/*    validate={[greaterThanZero]}*/}
    {/*    styleWrapper={{ marginLeft: 10 }}*/}
    {/*  />*/}
    {/*</div>*/}

    <div className="auth-form__row">
      <span className="auth-form__row__label">withdrawal:</span>
      <Field
        component={ui.Fields.BaseField}
        name="minWithdrawalAmount"
        props={{
          id: "minWithdrawalAmount",
          label: "min withdrawal amount",
          type: "number",
          fullWidth: true,
        }}
        styleWrapper={{ marginLeft: 10 }}
      />
      <Field
        component={ui.Fields.BaseField}
        name="maxWithdrawalAmount"
        props={{
          id: "maxWithdrawalAmount",
          label: "max withdrawal amount",
          type: "number",
          fullWidth: true,
        }}
        validate={[greaterThanZero]}
        styleWrapper={{ marginLeft: 10 }}
      />
    </div>
    <div style={{ alignSelf: "flex-start" }}>
      <Field
        name="alwaysQuote"
        id="alwaysQuote"
        component={renderCheckbox}
        label="Always quote"
      />
    </div>
    <Field
      component={ui.Fields.BaseField}
      name="step"
      props={{
        id: "step",
        label: "step",
        type: "number",
        fullWidth: true,
      }}
      validate={[greaterThanZero]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="digits"
      props={{
        id: "digits",
        label: "digits",
        type: "number",
        fullWidth: true,
      }}
      validate={[greaterThanZero]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="minInputAmount"
      props={{
        id: "minInputAmount",
        label: "min input amount",
        type: "number",
        fullWidth: true,
      }}
    />
    <div className="auth-form__row">
      <Field
        component={ui.Fields.BaseField}
        name="inputCommission"
        props={{
          id: "inputCommission",
          label: "input commission",
          type: "number",
        }}
      />
      <Field
        component={ui.Fields.BaseField}
        name="withdrawalCommission"
        props={{
          id: "withdrawalCommission",
          label: "withdraw commission",
          type: "number",
        }}
        styleWrapper={{ marginLeft: "auto" }}
      />
    </div>
    <Field
      component={ui.Fields.ImageField}
      name="icon"
      props={{
        id: "icon",
        label: "icon",
      }}
    />
    <Grid spacing={4} container justifyContent="center" alignContent="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {type === "create" ? "CREATE" : "SAVE"}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          CLOSE
        </Button>
      </Grid>
    </Grid>
  </form>
);

CryptoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: "cryptoForm",
  })
)(CryptoForm);
