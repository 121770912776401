import { getParams } from "helpers";

const usersHost = process.env.REACT_APP_SERVICE_USERS;
const paymentHost = process.env.REACT_APP_SERVICE_PAYMENT;
const dealsHost = process.env.REACT_APP_SERVICE_DEALS;
const hostMarket = `${process.env.REACT_APP_SERVICE_MARKET}`;
const chatHost = process.env.REACT_APP_SERVICE_CHAT;
const hostBuckets = process.env.REACT_APP_SERVICE_BUCKETS;
const exchangesHost = process.env.REACT_APP_SERVICE_EXCHANGES ;

const hostTranslations = process.env.REACT_APP_SERVICE_TRANSLATIONS;

const hostSocketChat = process.env.REACT_APP_WS_SERVICE_CHAT;

export default {
  getLoginUrl: () => `${usersHost}/auth`,
  getUserUrl: () => `${usersHost}/user`,
  getLogOutUrl: () => `${usersHost}/logout`,

  getDisputableDealsUrl: (body = {}) =>
    `${dealsHost}/deals/disputable${getParams(body)}`,
  getDisputableDealsWithTypeUrl: (type) => `${dealsHost}/deals/admin/${type}`,
  getSetAdminUrl: ({ dealId = 0 } = { dealId: 0 }) =>
    `${dealsHost}/deal/${dealId}/dispute/join`,
  getResolveDealUrl: ({ dealId = 0, role } = { dealId: 0 }) =>
    `${dealsHost}/deal/${dealId}/dispute/resolve?result=${role}`,
  getDealByIdUrl: (id) => `${dealsHost}/deal/${id}`,
  getDeaTimersUrl: () => `${dealsHost}/timers`,
  getPutDeaTimerUrl: (alias) => `${dealsHost}/timer/${alias}`,

  getExchangeUrl: () => `${exchangesHost}/exchanges/all`,
  getRefreshExchangesUrl: () => `${paymentHost}/exchange/sync`,
  getActionExchangeUrl: (id) => `${exchangesHost}/exchange/${id}`,
  getPostExchangeUrl: () => `${exchangesHost}/exchange/`,
  getChangeStatusExchangeUrl: (exchangeId) =>
    `${paymentHost}/exchange/${exchangeId}/change-status`,
  getCryptoUrl: () => `${paymentHost}/crypto`,
  getActionCryptoUrl: (cryptoId) => `${paymentHost}/crypto/${cryptoId}`,
  getChangeStatusCryptoUrl: (cryptoId) =>
    `${paymentHost}/crypto/${cryptoId}/change-status`,
  getFiatUrl: () => `${paymentHost}/fiat`,
  getActionFiatUrl: (fiatId) => `${paymentHost}/fiat/${fiatId}`,
  getBankUrl: (body = {}) => `${paymentHost}/bank${getParams(body)}`,
  getActionBankUrl: (bankId) => `${paymentHost}/bank/${bankId}`,
  getChangeStatusBankUrl: (bankId) =>
    `${paymentHost}/bank/${bankId}/change-status`,
  getPaymentUrl: () => `${paymentHost}/ps`,
  getActionPaymentUrl: (paymentId) => `${paymentHost}/ps/${paymentId}`,
  getChangeStatusPaymentUrl: (paymentId) =>
    `${paymentHost}/ps/${paymentId}/change-status`,
  getCommissionUrl: () => `${paymentHost}/commission`,
  getActionCommissionUrl: (commissionId) =>
    `${paymentHost}/commission/${commissionId}`,
  getCommissionBySymbolUrl: (symbol) =>
    `${paymentHost}/commission/symbol/${symbol}`,
  getChangeStatusCommissionUrl: (commissionId) =>
    `${paymentHost}/commission/${commissionId}/change-status`,
  getOfferByIdUrl: (id) => `${hostMarket}/offer/${id}`,
  getStopOfferByIdUrl: (id) => `${hostMarket}/offer/${id}/stop`,
  getStartOfferByIdUrl: (id) => `${hostMarket}/offer/${id}/start`,
  getProfitLimitsUrl: () => `${hostMarket}/limits`,
  getPutProfitLimitsUrl: () => `${hostMarket}/limits`,

  // чаты
  getChatUrl: id => `${chatHost}/chat/${id}`,
  getMessageUrl: (id) => `${chatHost}/chat/${id}/message`,
  getMessagesUrl: (id) => `${chatHost}/chat/${id}/messages`,
  getSystemMessageUrl: (id) => `${chatHost}/chat/${id}/message/system`,
  getAesKeyUrl: (id, key) => `${chatHost}/chat/${id}/aes?key=${key}`,
  getChatSocketUrl: (id, body = {}) => `${hostSocketChat}/ws/chat/${id}/connect${getParams(body)}`,

  // tickets
  getTicketsUrl: (body = {}) => `${chatHost}/tickets${getParams(body)}`,
  getCloseTicketUrl: (id) => `${chatHost}/ticket/${id}/close`,
  getAssignTicketUrl: (id) => `${chatHost}/ticket/${id}/assign`,

  // users
  getUsersUrl: (body = {}) => `${usersHost}/user/find${getParams(body)}`,
  getChangeRoleUrl: () => `${usersHost}/user/role`,
  getUserBlock: () => `${usersHost}/user/block`,
  getUserUnblock: () => `${usersHost}/user/unlock`,
  getSuspicious: () => `${usersHost}/users/suspect`,
  getPutSuspectCancel: (id, isCancel) => `${usersHost}/user/${id}/suspect/cancel?cancelTransactions=${isCancel}`,

  // buckets
  getFileBucketUrl: (bucketName) => `${hostBuckets}/file/${bucketName}`,
  getUploadingBucketUrl: (bucketName) =>
    `${hostBuckets}/api/v1/uploading/${bucketName}`,

  // translations
  getTranslationsUrl: () => `${hostTranslations}/locales`,
  getPutTranslationsUrl: () => `${hostTranslations}/locales/update`,
  getPostTranslationsUrl: () => `${hostTranslations}/locales/add`,
};
