import { bindActionCreators } from "redux";
import { compose } from "recompose";

import {
  requestAsync,
  updateEntities,
  updateResults,
} from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { deal } from "api";

import { getDeal, getTimers } from "./selectors";
import { reset } from "redux-form";

const DealApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        deal: getDeal(state),
        dealTimers: getTimers(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getDeal: (requestBody) => requestAsync(deal.getDeal(requestBody)),
            getDealTimers: (successCallback) => requestAsync(deal.getDealTimers(successCallback)),
            putDealTimer: (requestBody) =>
              requestAsync(deal.putDealTimer({ ...requestBody })),
            resetDeal: () => updateResults({ deal: {} }),
            updateDealTimer: (dealTimerEntities) =>
              updateEntities({ dealTimer: dealTimerEntities }),
            resetForm: () => dispatch(reset("dealAndOfferForm")),
          },
          dispatch
        ),
      })
    )
  )(WrappedComponent);

export default DealApiHOC;
