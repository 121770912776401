import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Immutable from "immutable";

import Chip from "@material-ui/core/Chip";

import "./style.scss";

const ChipField = ({ input: { value, onChange }, tags }) => (
  <div className="chip-field">
    {tags.map(({ id, title }) => (
      <Chip
        label={title}
        clickable
        onClick={() => {
          if (_.findIndex(value.toJS(), (o) => o === id) >= 0) {
            onChange(Immutable.List(_.without(value.toJS(), id)));
          } else {
            onChange(value.push(id));
          }
        }}
        color={
          _.findIndex(value.toJS(), (o) => o === id) >= 0
            ? "secondary"
            : "primary"
        }
      />
    ))}
  </div>
);

ChipField.propTypes = {
  tags: PropTypes.array.isRequired,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default ChipField;
