import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";

import { flowDirections } from "../../consts";
import { apiHOCs, forms } from "components";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const ProfitPercentScreen = ({
  getProfitLimits,
  profitLimits,
  putProfitLimits,
}) => {
  const [initialProfitPercents, setInitialProfitPercents] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    getProfitLimits();
  }, []);

  useEffect(() => {
    if (profitLimits.size) {
      const straightProfitLimits = profitLimits.find(
        (p) => p.get("flowAlias") === flowDirections.STRAIGHT
      );
      const swappedProfitLimits = profitLimits.find(
        (p) => p.get("flowAlias") === flowDirections.SWAPPED
      );
      setInitialProfitPercents({
        minProfitPercentStraight: straightProfitLimits.get("minProfitPercent"),
        maxProfitPercentStraight: straightProfitLimits.get("maxProfitPercent"),
        minProfitPercentSwapped: swappedProfitLimits.get("minProfitPercent"),
        maxProfitPercentSwapped: swappedProfitLimits.get("maxProfitPercent"),
      });
    }
  }, [profitLimits]);

  const handleSubmit = (data) => {
    const body = {
      limits: [
        {
          flowAlias: flowDirections.STRAIGHT,
          minProfitPercent: parseFloat(data.get("minProfitPercentStraight")),
          maxProfitPercent: parseFloat(data.get("maxProfitPercentStraight")),
        },
        {
          flowAlias: flowDirections.SWAPPED,
          minProfitPercent: parseFloat(data.get("minProfitPercentSwapped")),
          maxProfitPercent: parseFloat(data.get("maxProfitPercentSwapped")),
        },
      ],
    };

    putProfitLimits({
      body,
      successCallback: async () => {
        setSnackBarOpen(true);
      },
    });
  };

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <forms.ProfitPercentForm
          onSubmit={handleSubmit}
          initialValues={{ ...initialProfitPercents }}
        />
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleCloseSnackBar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={handleCloseSnackBar}
        >
          Лимиты маржи обновлены!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default compose(apiHOCs.ProfitLimitsApiHOC())(ProfitPercentScreen);
