import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { cryptocurrency } from "schemas";

export default (
  {
    resultKey = "cryptocurrency",
    alias,
    shortName,
    fullName,
    ticker,
    colorBg,
    color,
    limits,
    step,
    digits,
    icon,
    priority,
    minWithdrawalAmount,
    maxWithdrawalAmount,
    minInputAmount,
    inputCommission,
    withdrawalCommission,
    alwaysQuote,
  } = { resultKey: "cryptocurrency" }
) => ({
  url: endpoints.getCryptoUrl(),
  transform: (response) =>
    normalize(response.data, cryptocurrency.cryptocurrencySchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, cryptocurrency.cryptocurrencySchema)
      .result,
  }),
  queryKey: endpoints.getCryptoUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  body: {
    alias,
    shortName,
    fullName,
    ticker,
    colorBg,
    color,
    limits,
    step,
    digits,
    icon,
    priority,
    minWithdrawalAmount,
    maxWithdrawalAmount,
    minInputAmount,
    inputCommission,
    withdrawalCommission,
    alwaysQuote,
  },
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  },
  update: {
    cryptocurrency: (prevCryptocurrency = Immutable.Map(), crypto) =>
      prevCryptocurrency.mergeDeep(crypto),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => prevResult.push(result),
  },
});
