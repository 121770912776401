import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { bank as bankSchemas } from "schemas";

export default ({ resultKey = "banks" } = { resultKey: "banks" }) => ({
  url: endpoints.getBankUrl(),
  transform: (response) =>
    normalize(response.data, bankSchemas.arrayOfBankSchemas).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, bankSchemas.arrayOfBankSchemas)
      .result,
  }),
  queryKey: endpoints.getBankUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    bank: (prevBank = Immutable.Map(), bank) => prevBank.mergeDeep(bank),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => result,
  },
});
