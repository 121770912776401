import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";
import { apiHOCs, forms } from "components";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";

const defaultExchange = {
  alias: "",
  shortName: "",
  fullName: "",
  englishName: "",
  color: "#000000",
  priority: "1",
};

const ExchangeScreen = ({
  modal,
  setModal,
  exchange,
  getExchange,
  putExchange,
  deleteExchange,
  selected,
  setSelected,
  initExchange,
  setInitExchange,
}) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  function handleCloseSnackBar() {
    setSnackBarOpen(false);
  }

  function changeStatusExchange(exchangeInfo) {
    putExchange({
      id: exchangeInfo.get("id"),
      body: {
        alias: exchangeInfo.get("alias"),
        shortName: exchangeInfo.get("shortName"),
        fullName: exchangeInfo.get("fullName"),
        englishName: exchangeInfo.get("englishName"),
        color: exchangeInfo.get("color"),
        icon: exchangeInfo.get("icon"),
        priority: parseInt(exchangeInfo.get("priority"), 10),
        isActive: !exchangeInfo.get("isActive"),
      },
      successCallback: () => {
        getExchange();
      },
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Exchange
        </Typography>
      </Paper>
      <Paper
        elevation={1}
        style={{
          width: 820,
          padding: 10,
        }}
      >
        <Button variant="contained" onClick={getExchange}>
          Обновить список бирж
        </Button>
        <List>
          {exchange.map((exchangeInfo) => (
            <ListItem
              key={exchangeInfo.get("id")}
              button
              onClick={() => {
                setInitExchange({
                  alias: exchangeInfo.get("alias"),
                  shortName: exchangeInfo.get("shortName"),
                  fullName: exchangeInfo.get("fullName"),
                  englishName: exchangeInfo.get("englishName"),
                  color: exchangeInfo.get("color"),
                  priority: exchangeInfo.get("priority"),
                  icon: exchangeInfo.get("icon"),
                });
                setSelected(exchangeInfo);
                setModal(true);
              }}
            >
              <Grid
                container
                spacing={2}
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <ListItemText
                    primary={exchangeInfo.get("alias")}
                    secondary={exchangeInfo.get("fullName")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItemText
                    secondary={
                      exchangeInfo.get("isAvailable")
                        ? "Доступна"
                        : "Недоступна"
                    }
                    style={{ textAlign: "right", paddingRight: 20 }}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  changeStatusExchange(exchangeInfo);
                }}
                color={exchangeInfo.get("isActive") ? "secondary" : "primary"}
              >
                {exchangeInfo.get("isActive") ? "DEACTIVATE" : "ACTIVATE"}
              </Button>
              <IconButton
                style={{ marginLeft: 20 }}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteExchange({
                    id: exchangeInfo.get("id"),
                    successCallback: () => {
                      getExchange();
                    },
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Paper>
      <Modal open={modal}>
        <Paper
          elevation={1}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            height: 500,
            overflow: "auto",
            padding: 20,
          }}
        >
          <forms.ExchangeForm
            onSubmit={(body) => {
              if (selected.size) {
                putExchange({
                  id: selected.get("id"),
                  body: {
                    alias: body.get("alias"),
                    shortName: body.get("shortName"),
                    fullName: body.get("fullName"),
                    englishName: body.get("englishName"),
                    color: body.get("color"),
                    icon: body.get("icon"),
                    priority: parseInt(body.get("priority"), 10),
                    isActive: selected.get("isActive"),
                  },
                  successCallback: (resp) => {
                    if (resp.errorCode) {
                      const err = resp.data;
                      alert(`putCrypto error: ${err}`);
                      console.log(err);
                    } else {
                      setModal(false);
                      setSelected(new Map());
                      getExchange();
                    }
                  },
                });
              }
            }}
            handleClose={() => {
              setModal(false);
              setSelected(new Map());
            }}
            type={selected === "" ? "create" : "save"}
            initialValues={initExchange}
          />
        </Paper>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        open={snackBarOpen}
        onClose={handleCloseSnackBar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={handleCloseSnackBar}
        >
          Список бирж обновлен
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

ExchangeScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  exchange: PropTypes.object.isRequired,
  getExchange: PropTypes.func.isRequired,
  putExchange: PropTypes.func.isRequired,
  deleteExchange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  initExchange: PropTypes.object.isRequired,
  setInitExchange: PropTypes.func.isRequired,
};

export default compose(
  withState("modal", "setModal", false),
  withState("initExchange", "setInitExchange", { ...defaultExchange }),
  withState("selected", "setSelected", new Map()),
  apiHOCs.ExchangeApiHOC()
)(ExchangeScreen);
