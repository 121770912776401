import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import * as PropTypes from "prop-types";
import { CardContent, Collapse } from "@material-ui/core";
import DealInfoBlock from "../DealInfoBlock";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EmptyTableIcon from "assets/img/emptyPlaceholders/empty_offers.svg";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  emptyContainer: {
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    maxHeight: 600,
  },
});

const Row = ({
  row,
  columns,
  deals,
  showDealInfo,
  onClick,
  withCursorPointer,
}) => {
  const [expanded, setExpanded] = useState();
  const handleExpandClick = (id, event) => {
    event.stopPropagation();
    if (!expanded) {
      showDealInfo(id);
    }

    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow
        style={{ cursor: withCursorPointer ? "pointer" : "auto" }}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.code}
        onClick={onClick}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => handleExpandClick(row.id, e)}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format && typeof value === "number"
                ? column.format(value)
                : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <DealInfoBlock
                deal={deals && deals.find((d) => d.get("id") === row["id"])}
              />
            </CardContent>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

function StickyHeadTable({
  columns,
  rows,
  showDealInfo,
  deals,
  onClickItem,
  meta,
  onChangePage,
}) {
  const classes = useStyles();

  if (!rows.length && !rows.size) {
    return (
      <Paper className={classes.emptyContainer}>
        <img src={EmptyTableIcon} />
        <Typography variant={"h6"} color={"textSecondary"}>
          No result...
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <Row
                  row={row}
                  columns={columns}
                  showDealInfo={showDealInfo}
                  deals={deals}
                  onClick={() => {
                    if (typeof onClickItem === "function") {
                      onClickItem(row.id);
                    } else if (typeof onClickItem === "object") {
                      if(onClickItem.type === "chat") {
                        onClickItem.handler(row.id);
                      }
                    }
                  }}
                  withCursorPointer={typeof onClickItem === "function" || typeof onClickItem === "object"}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        lang={"ru"}
        component="div"
        count={meta.get("totalCount")}
        rowsPerPage={meta.get("limit")}
        labelRowsPerPage={""}
        rowsPerPageOptions={[]}
        page={meta.get("page") - 1}
        onChangePage={onChangePage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count}`
        }
      />
    </Paper>
  );
  //  limit: 10
  // offset: 0
  // page: 1
  // totalCount: 2
  // totalPageCount:
}

StickyHeadTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  onClickItem: PropTypes.func,
  meta: PropTypes.object.isRequired,
};

StickyHeadTable.defaultProps = {
  columns: [],
  rows: [],
  onClickItem: null,
};

export default StickyHeadTable;
