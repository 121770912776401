import { bindActionCreators } from "redux";
import { compose } from "recompose";
import endpoints from "api/endpoints";
import {
  requestAsync,
  querySelectors,
} from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { auth } from "api";

import { getUser } from "./selectors";

const AuthApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        user: getUser(state),
        loginIsFetching:
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getLoginUrl(),
          }) || false,
        getUserIsInitializing: !querySelectors.lastUpdated(
          state.get("queries"),
          { queryKey: endpoints.getUserUrl() }
        ),
        getUserSuccess:
          querySelectors.status(state.get("queries"), {
            queryKey: endpoints.getUserUrl(),
          }) === 200,
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            postLogin: ({ requestBody }) =>
              requestAsync(auth.postLogin({ requestBody })),
            getUser: () => requestAsync(auth.getUser({})),
            postLogOut: (successCallback) =>
              requestAsync(auth.postLogOut(successCallback)),
          },
          dispatch
        ),
      })
    )
  )(WrappedComponent);

export default AuthApiHOC;
