import getUsers from "./getUsers";
import putChangeUserRole from "./putChangeUserRole";
import putUserBlock from "./putUserBlock";
import putUserUnblock from "./putUserUnblock";
import getSuspicious from "./getSuspicious";
import putSuspectCancel from "./putSuspectCancel";

export default {
  getUsers,
  putChangeUserRole,
  putUserBlock,
  putUserUnblock,
  getSuspicious,
  putSuspectCancel
};
