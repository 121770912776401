import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { dealStatuses } from "../../../consts";

const useStyles = makeStyles({
  row: {
    transition: "background 0.1s ease",
    "&:hover": {
      background: "#c3c3c3",
    },
  },
});

function DealInfoBlock({ deal }) {
  const classes = useStyles();

  const dealStatus = useMemo(() => {
    if (!deal) return null;

    switch (deal.get("status")) {
      case dealStatuses.CONFIRMED: {
        return "Покупатель отправил запрос, продавец не подтвердил начало сделки";
      }
      // case dealStatuses.: {
      //   return "Сделка отменена";
      // }
      case dealStatuses.ACCEPTED: {
        return "Продавец подтвердил начало сделки, сделка в процессе";
      }
      case dealStatuses.FUNDS_SENT: {
        return "Ожидание подтверждения получения денежного перевода";
      }
      case dealStatuses.DISPUTE: {
        return "Спорная сделка";
      }
      case dealStatuses.FINISHED: {
        return "Сделка завершена";
      }
      default:
        return "Неизвестный статус";
    }
  }, [deal]);

  const disputeStatus = useMemo(() => {
    if (!deal) return null;
    if (deal.get("status") === dealStatuses.DISPUTE) {
      if (!deal.getIn(["dispute", "adminID"])) {
        return "Спор открыт, ожидание администратора";
      }
      if (deal.getIn(["dispute", "winnerID"])) {
        const winnerName =
          deal.getIn(["dispute", "winnerID"]) === deal.get("agentID")
            ? deal.getIn(["agent", "username"])
            : deal.getIn(["contragent", "username"]);
        return `Спор закрыт в сторону ${winnerName}`;
      }
      return "Администратор рассматривает спорную сделку";
    }
    return null;
  }, [deal]);

  if (!deal) return null;

  return (
    <div>
      <h4>Информация по сделке: </h4>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Дата создания сделки:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {new Date(Date.parse(deal.get("createdAt"))).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Статус сделки:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">{dealStatus}</Typography>
          </Grid>
        </Grid>
        {disputeStatus && (
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.row}
          >
            <Grid item xs={6}>
              Статус спора:
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">{disputeStatus}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Продавец:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.getIn(["contragent", "username"])}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Покупатель:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.getIn(["agent", "username"])}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Направление:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.get("contragentSendCurrencyAlias").toUpperCase()}/
              {deal.get("agentSendCurrencyAlias").toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Курс:{" "}
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.getIn(["rate", "isInverted"])
                ? `${deal.getIn(["rate", "value"])} ${deal
                    .get("contragentSendCurrencyAlias", "")
                    .toUpperCase()} за 1 ${deal
                    .get("agentSendCurrencyAlias", "")
                    .toUpperCase()}`
                : `${deal.getIn(["rate", "value"])} ${deal
                    .get("agentSendCurrencyAlias", "")
                    .toUpperCase()} за 1 ${deal
                    .get("contragentSendCurrencyAlias", "")
                    .toUpperCase()}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Продавец получит:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.get("contragentGets")}{" "}
              {deal.get("agentSendCurrencyAlias").toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Продавец отдаст:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.get("contragentSends")}{" "}
              {deal.get("contragentSendCurrencyAlias").toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Покупатель получит:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.get("agentGets")}{" "}
              {deal.get("contragentSendCurrencyAlias").toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={6}>
            Покупатель отдаст:
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              {deal.get("agentSends")}{" "}
              {deal.get("agentSendCurrencyAlias").toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.row}
      >
        <Grid item xs={6}>
          Кошелек списания у продавца
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">
            Тип: {deal.get("contragentSendsPaymentType")}{" "}
            {deal.get("contragentSendsPayment") &&
              `, ID: ${deal.getIn(["contragentSendsPayment", "id"])}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.row}
      >
        <Grid item xs={6}>
          Кошелек поступления у продавца
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">
            Тип: {deal.get("contragentGetsPaymentType")}{" "}
            {deal.get("contragentGetsPayment") &&
              `, ID: ${deal.getIn(["contragentGetsPayment", "id"])}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.row}
      >
        <Grid item xs={6}>
          Кошелек списания у покупателя
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">
            Тип: {deal.get("agentSendsPaymentType")}{" "}
            {deal.get("agentSendsPayment") &&
              `, ID: ${deal.getIn(["agentSendsPayment", "id"])}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={classes.row}
      >
        <Grid item xs={6}>
          Кошелек поступления у покупателя
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">
            Тип: {deal.get("agentGetsPaymentType")}{" "}
            {deal.get("agentGetsPayment") &&
              `, ID: ${deal.getIn(["agentGetsPayment", "id"])}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

DealInfoBlock.propTypes = {
  deal: PropTypes.object.isRequired,
};

export default DealInfoBlock;
