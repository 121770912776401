import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import { compose, getContext, lifecycle, withState } from "recompose";

import TicketsTable from "components/ui/TicketsTable";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const ClosedPrivateChatsScreen = ({
  tickets,
  router,
  user
}) => {
  const activeTickets = useMemo(() => {
    return tickets.filter((ticket) => {
      return ticket.get('adminID') === user.get("id")
    })
  }, [tickets]);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 1100,
          marginBottom: 20,
          padding: 10,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Closed private chats
        </Typography>
      </Paper>
      <Paper
        elevation={1}
        style={{
          width: 1120,
        }}
      >
        <TicketsTable 
          rows={activeTickets.sort((t1, t2) => {
            return new Date(t2.get('createdAt')) - new Date(t1.get('createdAt'));
          })}
          assign={(id) => {
            router.history.push(`/chats/${id}`);
          }}
          isClose={true}
        />
      </Paper>
    </div>
  );
}

ClosedPrivateChatsScreen.defaultProps = {
}

export default compose(
  apiHOCs.ChatApiHOC(),
  apiHOCs.AuthApiHOC(),

  lifecycle({
    componentDidMount() {
      this.props.getTickets({closed: true});
    },
  }),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  })
)(ClosedPrivateChatsScreen);