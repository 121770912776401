import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import {
  compose,
  getContext,
  withHandlers,
  withStateHandlers,
} from "recompose";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import StickyHeadTable from "../../components/ui/StickyHeadTable";
import moment from "moment";
import TableSkeleton from "../../components/ui/Skeletons/TableSkeleton";

const OpenDisputableDealsScreen = ({
  shortActiveDisputableDeals,
  getActiveDisputableDealsIsFetching,
  getDeal,
  disputableDeals,
  getShortActiveDisputableDeals,
  shortActiveDisputableDealsMeta: meta,
  router,
  putResolveDeal,
  isOpenConfirmModal,
  openConfirmModal,
  closeConfirmModal,
  confirmRequest,
  confirmInfo,
  user,
}) => {
  useEffect(() => {
    getShortActiveDisputableDeals({
      limit: 10,
      page: 1,
      adminID: user.get("id"),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    getShortActiveDisputableDeals({
      limit: 10,
      page: newPage + 1,
      adminID: user.get("id"),
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Active disputes
        </Typography>
      </Paper>
      {getActiveDisputableDealsIsFetching ? (
        <TableSkeleton width={820} />
      ) : (
        <Paper
          elevation={1}
          style={{
            width: 820,
          }}
        >
          <StickyHeadTable
            deals={disputableDeals}
            onChangePage={handleChangePage}
            showDealInfo={(id) => {
              getDeal({ id, resultKey: "disputableDeals" });
            }}
            onClickItem={{
              handler: (id) => { 
                getDeal({ id, resultKey: "disputableDeals" }).then(({body}) => {
                  router.history.push(`/chats/${body.data.deal.chatID}`);
                });
              },
              type: "chat"
            }}
            columns={[
              { id: "number", label: "#" },
              { id: "id", label: "ID" },
              { id: "createdAt", label: "Дата создания" },
              { id: "sellerWin", label: "" },
              { id: "buyerWin", label: "" },
            ]}
            rows={shortActiveDisputableDeals
              .sort(
                (prev, next) =>
                  new Date(next.get("createdAt")) -
                  new Date(prev.get("createdAt"))
              )
              .map((deal) => ({
                number: deal.get("serialNumber"),
                id: deal.get("id"),
                chatID: deal.get("chatID"),
                createdAt: moment(deal.get("createdAt")).format(
                  "MMMM Do YYYY, h:mm:ss a"
                ),
                sellerWin: (
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      openConfirmModal({
                        id: deal.get("id"),
                        role: "contragent",
                      });
                    }}
                  >
                    Contragent
                  </Button>
                ),
                buyerWin: (
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      openConfirmModal({
                        id: deal.get("id"),
                        role: "agent",
                      });
                    }}
                    style={{
                      marginRight: 20,
                    }}
                  >
                    Agent
                  </Button>
                ),
              }))}
            meta={meta}
          />
        </Paper>
      )}
      <Dialog
        open={isOpenConfirmModal}
        onClose={closeConfirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm action</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Close the dispute in favor of the "}
            <b>{confirmInfo.role}</b>
            {" ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmModal} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => confirmRequest(putResolveDeal(confirmInfo))}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

OpenDisputableDealsScreen.propTypes = {
  shortActiveDisputableDeals: PropTypes.object,
  router: PropTypes.object,
  putResolveDeal: PropTypes.func,
  isOpenConfirmModal: PropTypes.bool.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  closeConfirmModal: PropTypes.func.isRequired,
  confirmRequest: PropTypes.func.isRequired,
  confirmInfo: PropTypes.object.isRequired,
};

export default compose(
  apiHOCs.DisputableDealsApiHOC(),
  apiHOCs.AuthApiHOC(),

  withStateHandlers(
    { isOpenConfirmModal: false, confirmInfo: {} },
    {
      openConfirmModal: () => (confirmInfo = {}) => ({
        isOpenConfirmModal: true,
        confirmInfo,
      }),
      closeConfirmModal: () => () => ({
        isOpenConfirmModal: false,
        confirmInfo: {},
      }),
    }
  ),
  withHandlers({
    confirmRequest: ({ closeConfirmModal }) => (callback) => {
      if (typeof callback === "function") {
        callback();
      }

      closeConfirmModal();
    },
  }),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  })
)(OpenDisputableDealsScreen);
