import { pack, importAesGcmKey } from './utils';

const encode = (data) => {
  const encoder = new TextEncoder();
  return encoder.encode(data);
};

const generateIv = () => window.crypto.getRandomValues(new Uint8Array(12));

/**
 * Функция для шифрования сообщения
 *
 * @param message {String} Сообщение для дешифровки в base64
 * @param key {ArrayBuffer} Ключ
 * @return {Promise<Object>} Зашифрованный текст и вектор инициализации в base64
 */
export default async (message, key) => {
  const encoded = encode(message);
  const iv = generateIv();
  const cryptoKey = await importAesGcmKey(key);

  const cipher = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    cryptoKey,
    encoded,
  );

  return {
    body: pack(cipher),
    iv: pack(iv),
  };
};
