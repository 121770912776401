import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { suspect } from "schemas";

export default () =>
  requestAsync({
    url: endpoints.getSuspicious(),
    queryKey: endpoints.getSuspicious(),
    transform: (response) => normalize(response.data, suspect.schemasArray).entities,
    transformResult: (response) => ({
      suspects: normalize(response.data, suspect.schemasArray).result,
    }),
    meta: {
      authToken: true,
    },
    options: {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    },
    update: {
      suspect: (_, result) => result
    },
    updateResult: {
      suspects: (_, result) => result
    },
  });
