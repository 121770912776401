import getChat from './getChat';
import getMessages from "./getMessages";
import postMessage from "./postMessage";
import getAesKey from "./getAesKey";
import postSystemMessage from "./postSystemMessage";
import getTickets from "./getTickets";
import putAssignTicket from "./putAssignTicket";
import putCloseTicket from "./putCloseTicket";

export default {
  getChat,
  getMessages,
  postMessage,
  getAesKey,
  postSystemMessage,
  getTickets,
  putAssignTicket,
  putCloseTicket
};
