import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { timer as timerSchema } from "schemas";

export default (successCallback) => ({
  url: endpoints.getDeaTimersUrl(),
  transform: (response) =>
    normalize(response.data, timerSchema.arrayOfTimerSchemas).entities,
  transformResult: (response) => ({
    dealTimers: normalize(response.data, timerSchema.arrayOfTimerSchemas)
      .result,
  }),
  queryKey: endpoints.getDeaTimersUrl(),
  meta: {
    authToken: true,
    successCallback
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    dealTimer: (prevEntities = Immutable.Map(), entities) =>
      prevEntities.mergeDeep(entities),
  },
  updateResult: {
    dealTimers: (prevResult, result) => result,
  },
});
