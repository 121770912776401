import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  ActivePrivateChatsScreen,
  OpenPrivateChatsScreen,
  ClosedPrivateChatsScreen
} from "../../../screens";
import { Badge } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: "100%",
    width: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [openChatsCount, setOpenChatsCount] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeOpenChatsCount = (count) => {
    setOpenChatsCount(count);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="standard"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          label={
            <Badge
              badgeContent={openChatsCount}
              color="secondary"
              overlap="rectangle"
            >
              <Typography
                variant="body2"
                style={{ paddingRight: openChatsCount > 0 ? 10 : 0 }}
              >
                Start Private Chats
              </Typography>
            </Badge>
          }
          {...a11yProps(0)}
        />
        <Tab
          label={<Typography variant="body2">Active Private Chats</Typography>}
          {...a11yProps(1)}
        />
        <Tab
          label={<Typography variant="body2">Closed Private Chats</Typography>}
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <OpenPrivateChatsScreen
          onChangeOpenChatsCount={handleChangeOpenChatsCount}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ActivePrivateChatsScreen />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ClosedPrivateChatsScreen />
      </TabPanel>
    </div>
  );
}