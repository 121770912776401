import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form/immutable";
import { ui } from "components";
import Button from "@material-ui/core/Button";

import "./style.scss";

const AuthForm = ({ handleSubmit }) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="email"
      props={{
        id: "email",
        label: "E-mail",
        fullWidth: true,
        style: {
          marginBottom: 10,
        },
      }}
    />
    <Field
      component={ui.Fields.BaseField}
      name="password"
      props={{
        id: "password",
        label: "Password",
        type: "password",
        fullWidth: true,
        style: {
          marginBottom: 10,
        },
      }}
    />
    <Field
      component={ui.Fields.ReCAPTCHAField}
      name="captcha"
      props={{
        styleWrapper: {
          marginTop: 20,
        },
        reCaptchaRef: React.createRef(),
      }}
    />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
      }}
    >
      Login
    </Button>
  </form>
);

AuthForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: "authForm",
  })
)(AuthForm);
