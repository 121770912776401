import React from "react";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";
import { apiHOCs, forms } from "components";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const defaultCrypto = {
  alias: "",
  shortName: "",
  fullName: "",
  ticker: "",
  colorBg: "#000000",
  color: "#000000",
  minLimit: 0,
  maxLimit: 0,
  step: 0,
  digits: 0,
  icon: "",
  minInputAmount: 0,
  inputCommission: 0,
  withdrawalCommission: 0,
};

const CryptocurrencyScreen = ({
  modal,
  setModal,
  cryptocurrency,
  postCrypto,
  putCrypto,
  initCrypto,
  setInitCrypto,
  selected,
  setSelected,
  changeStatusCrypto,
  deleteCrypto,
  getCrypto,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: "center",
        }}
      >
        Cryptocurrency
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {cryptocurrency.map((crypto) => (
          <ListItem
            key={crypto.get("id")}
            button
            onClick={() => {
              const limitsFiat = crypto
                .get("limits")
                .find((item) => item.get("type") === "fiat");
              const limitsCrypto = crypto
                .get("limits")
                .find((item) => item.get("type") === "crypto");
              setInitCrypto({
                alias: crypto.get("alias"),
                shortName: crypto.get("shortName"),
                fullName: crypto.get("fullName"),
                ticker: crypto.get("ticker"),
                colorBg: crypto.get("colorBg"),
                color: crypto.get("color"),
                minLimit: crypto.get("minLimit"),
                maxLimit: crypto.get("maxLimit"),
                step: crypto.get("step"),
                digits: crypto.get("digits"),
                icon: crypto.get("icon"),
                priority: crypto.get("priority"),
                minLimitCrypto: limitsCrypto.get("min"),
                maxLimitCrypto: limitsCrypto.get("max"),
                minLimitFiat: limitsFiat.get("min"),
                maxLimitFiat: limitsFiat.get("max"),
                minWithdrawalAmount: crypto.get("minWithdrawalAmount"),
                maxWithdrawalAmount: crypto.get("maxWithdrawalAmount"),
                minInputAmount: crypto.get("minInputAmount"),
                inputCommission: crypto.get("inputCommission"),
                withdrawalCommission: crypto.get("withdrawalCommission"),
                alwaysQuote: crypto.get("alwaysQuote"),
              });
              setSelected(crypto.get("id"));
              setModal(true);
            }}
          >
            <ListItemText
              primary={crypto.get("alias")}
              secondary={crypto.get("fullName")}
            />
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                changeStatusCrypto(crypto.get("id"));
              }}
              color={crypto.get("isActive") ? "secondary" : "primary"}
            >
              {crypto.get("isActive") ? "DEACTIVATE" : "ACTIVATE"}
            </Button>
            <IconButton
              style={{ marginLeft: 20 }}
              onClick={(e) => {
                e.stopPropagation();
                deleteCrypto(crypto.get("id")).then(() => {
                  getCrypto();
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
    >
      <Paper
        elevation={1}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          height: 500,
          overflow: "auto",
          padding: 20,
        }}
      >
        {modal && (
          <forms.CryptoForm
            onSubmit={(body) =>
              selected === ""
                ? postCrypto({
                    alias: body.get("alias"),
                    shortName: body.get("shortName"),
                    fullName: body.get("fullName"),
                    ticker: body.get("ticker"),
                    color: body.get("color"),
                    colorBg: body.get("colorBg"),
                    icon: body.get("icon"),
                    priority: parseInt(body.get("priority"), 0),
                    minWithdrawalAmount: +body.get("minWithdrawalAmount"),
                    maxWithdrawalAmount: +body.get("maxWithdrawalAmount"),
                    limits: [
                      {
                        type: "crypto",
                        min: +body.get("minLimitCrypto"),
                        max: +body.get("maxLimitCrypto"),
                      },
                      {
                        // Рудиментный фиатный, посылаем любые значения
                        type: "fiat",
                        min: +body.get("minLimitCrypto"),
                        max: +body.get("maxLimitCrypto")
                      }
                    ],
                    alwaysQuote: body.get("alwaysQuote"),
                    digits: +body.get("digits"),
                    step: +body.get("step"),
                    minInputAmount: +body.get("minInputAmount"),
                    inputCommission: +body.get("inputCommission"),
                    withdrawalCommission: +body.get("withdrawalCommission"),
                  }).then((resp) => {
                    if (!resp.body.errorCode) {
                      setModal(false);
                    } else {
                      const err = JSON.stringify(resp.body.data);
                      alert(`postCrypto error: ${err}`);
                      console.log(err);
                    }
                  })
                : putCrypto({
                    alias: body.get("alias"),
                    shortName: body.get("shortName"),
                    fullName: body.get("fullName"),
                    ticker: body.get("ticker"),
                    color: body.get("color"),
                    colorBg: body.get("colorBg"),
                    icon: body.get("icon"),
                    priority: parseInt(body.get("priority"), 0),
                    minWithdrawalAmount: +body.get("minWithdrawalAmount"),
                    maxWithdrawalAmount: +body.get("maxWithdrawalAmount"),
                    alwaysQuote: body.get("alwaysQuote"),
                    digits: +body.get("digits"),
                    step: +body.get("step"),
                    limits: [
                      {
                        type: "crypto",
                        min: +body.get("minLimitCrypto"),
                        max: +body.get("maxLimitCrypto"),
                      },
                      {
                        // Рудиментный фиатный, посылаем любые значения
                        type: "fiat",
                        min: +body.get("minLimitCrypto"),
                        max: +body.get("maxLimitCrypto")
                      }
                    ],
                    cryptoId: selected,
                    minInputAmount: +body.get("minInputAmount"),
                    inputCommission: +body.get("inputCommission"),
                    withdrawalCommission: +body.get("withdrawalCommission"),
                  }).then((resp) => {
                    if (!resp.body.errorCode) {
                      setModal(false);
                    } else {
                      const err = JSON.stringify(resp.body.data);
                      alert(`putCrypto error: ${err}`);
                      console.log(err);
                    }
                  })
            }
            type={selected === "" ? "create" : "save"}
            handleClose={() => {
              setModal(false);
              setInitCrypto({ ...defaultCrypto });
              setSelected("");
            }}
            initialValues={initCrypto}
          />
        )}
      </Paper>
    </Modal>
  </div>
);

CryptocurrencyScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  cryptocurrency: PropTypes.object.isRequired,
  postCrypto: PropTypes.func.isRequired,
  putCrypto: PropTypes.func.isRequired,
  changeStatusCrypto: PropTypes.func.isRequired,
  initCrypto: PropTypes.object.isRequired,
  setInitCrypto: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  deleteCrypto: PropTypes.func.isRequired,
  getCrypto: PropTypes.func.isRequired,
};

export default compose(
  withState("modal", "setModal", false),
  withState("initCrypto", "setInitCrypto", { ...defaultCrypto }),
  withState("selected", "setSelected", ""),
  apiHOCs.CryptocurrencyApiHOC()
)(CryptocurrencyScreen);
