import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { payment as paymentSchemas } from "schemas";

export default (
  {
    resultKey = "payment",
    alias,
    shortName,
    fullName,
    englishName,
    colorBg,
    color,
    fiats,
    kit,
    icon,
  } = { resultKey: "payment" }
) => ({
  url: endpoints.getPaymentUrl(),
  transform: (response) =>
    normalize(response.data, paymentSchemas.paymentSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, paymentSchemas.paymentSchema).result,
  }),
  queryKey: endpoints.getPaymentUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  body: {
    alias,
    shortName,
    fullName,
    englishName,
    colorBg,
    color,
    fiats,
    kit,
    icon,
  },
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  },
  update: {
    payment: (prevPayment = Immutable.Map(), payment) =>
      prevPayment.mergeDeep(payment),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => prevResult.push(result),
  },
});
