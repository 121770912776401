import { bindActionCreators } from "redux";
import { compose } from "recompose";

import {
  querySelectors,
  requestAsync,
} from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { deal } from "api";

import {
  getDisputableDeals,
  getUser,
  getDeal,
  getShortDisputableDeals,
  getDisputableDealsMeta,
} from "./selectors";
import endpoints from "../../../api/endpoints";

const DisputableDealsApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        user: getUser(state),
        disputableDeals: getDisputableDeals(state, "disputableDeals"),
        shortDisputableDeals: getShortDisputableDeals(
          state,
          "shortDisputableDeals"
        ),
        shortClosedDisputableDeals: getShortDisputableDeals(
          state,
          "shortClosedDisputableDeals"
        ),
        shortActiveDisputableDeals: getShortDisputableDeals(
          state,
          "shortActiveDisputableDeals"
        ),
        activeDisputableDeals: getDisputableDeals(
          state,
          "activeDisputableDeals"
        ),
        shortDisputableDealsMeta: getDisputableDealsMeta(
          state,
          "shortDisputableDealsMeta"
        ),
        shortActiveDisputableDealsMeta: getDisputableDealsMeta(
          state,
          "shortActiveDisputableDealsMeta"
        ),
        shortClosedDisputableDealsMeta: getDisputableDealsMeta(
          state,
          "shortClosedDisputableDealsMeta"
        ),
        deal: getDeal(state),
        getClosedDisputableDealsIsFetching:
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getDisputableDealsWithTypeUrl(),
          }) || false,
        getActiveDisputableDealsIsFetching:
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getDisputableDealsWithTypeUrl(),
          }) || false,
        getDisputableDealsIsFetching:
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getDisputableDealsUrl(),
          }) || false,
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getShortDisputableDeals: (requestBody) =>
              requestAsync(deal.getShortDisputableDeals(requestBody)),
            getShortActiveDisputableDeals: (requestBody) =>
              requestAsync(deal.getShortActiveDisputableDeals(requestBody)),
            getShortClosedDisputableDeals: (requestBody) =>
              requestAsync(deal.getShortClosedDisputableDeals(requestBody)),
            getDisputableDealsWithType: (type) =>
              requestAsync(deal.getDisputableDealsWithType(type)),
            putConfirmAdmin: (dealId) =>
              requestAsync(deal.putConfirmAdmin(dealId)),
            putResolveDeal: (requestBody) =>
              requestAsync(deal.putResolveDeal(requestBody)),
            getDeal: (requestBody) => requestAsync(deal.getDeal(requestBody)),
          },
          dispatch
        ),
      })
    )
  )(WrappedComponent);

export default DisputableDealsApiHOC;
