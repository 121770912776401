import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { user } from "schemas";

export const getUser = createSelector(
  (state) => getResults(state).get("user", Immutable.Map()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, user.schema, entities)
);
