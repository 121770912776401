import getCrypto from "./getCrypto";
import postCrypto from "./postCrypto";
import putCrypto from "./putCrypto";
import changeStatusCrypto from "./changeStatusCrypto";
import deleteCrypto from "./deleteCrypto";

export default {
  getCrypto,
  postCrypto,
  putCrypto,
  changeStatusCrypto,
  deleteCrypto,
};
