import endpoints from "api/endpoints";

export default ({ id, successCallback }) => ({
  url: endpoints.getCloseTicketUrl(id),
  queryKey: endpoints.getCloseTicketUrl(),
  meta: {
    authToken: true,
    successCallback,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
});