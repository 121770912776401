import React from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import { compose, getContext, lifecycle, withState } from "recompose";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PrivateChatTabs from "../../components/ui/PrivateChatTabs";

const PrivateChatsScreen = () => (
  <div
    style={{
      padding: "20px",
      maxWidth: "1400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    }}
  >
    <PrivateChatTabs onChangeOpenChatsCount={() => {}} />
  </div>
);

PrivateChatsScreen.propTypes = {
  onChangeOpenDisputesCount: PropTypes.func.isRequired,
  disputableDeals: PropTypes.object,
  putConfirmAdmin: PropTypes.func,
  dealId: PropTypes.string,
  setDealId: PropTypes.func,
  router: PropTypes.any,
};

export default compose(apiHOCs.ChatApiHOC())(PrivateChatsScreen);
