import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form/immutable";
import { required } from "validators";
import { ui } from "components";
import Immutable from "immutable";
import Button from "@material-ui/core/Button";

import "./style.scss";

const CommissionForm = ({
  handleSubmit,
  type,
  currenciesList,
  initialValues,
}) => (
  <form className="commission-form" autoComplete="off">
    <div
      className={`commission-form__field${
        type !== "create" ? " commission-form__field_hide" : ""
      }`}
    >
      <Field
        component={ui.Fields.DropdownField}
        name="tickerSell"
        props={{
          id: "tickerSell",
          label: "Ticker sell",
        }}
        dropdownItems={currenciesList}
        validate={[required]}
      />
    </div>
    <div
      className={`commission-form__field${
        type !== "create" ? " commission-form__field_hide" : ""
      }`}
    >
      <Field
        component={ui.Fields.DropdownField}
        name="tickerBuy"
        props={{
          id: "tickerBuy",
          label: "Ticker Buy",
        }}
        dropdownItems={currenciesList}
        validate={[required]}
      />
    </div>
    {type !== "create" && (
      <span className="commission-form__pair">
        {`${initialValues.get("tickerSell")} -> ${initialValues.get(
          "tickerBuy"
        )}`}
      </span>
    )}
    <Field
      component={ui.Fields.BaseField}
      name="commissionPercentSell"
      props={{
        id: "commissionPercentSell",
        label: "Commission percent (for seller currency)",
        fullWidth: true,
        type: "number",
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="commissionPercentBuy"
      props={{
        id: "commissionPercentBuy",
        label: "Commission percent (for buyer currency)",
        fullWidth: true,
        type: "number",
      }}
      validate={[required]}
    />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === "create" ? "CREATE" : "SAVE"}
    </Button>
  </form>
);

CommissionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  currenciesList: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

CommissionForm.defaultProps = {
  initialValues: Immutable.Map(),
};

export default compose(
  reduxForm({
    form: "commissionForm",
  })
)(CommissionForm);
