import React from "react";
import PropTypes from "prop-types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "./style.scss";

const DropdownField = ({
  input: { value, onChange },
  id,
  label,
  styleWrapper,
  style,
  dropdownItems,
  meta: { touched, error },
}) => (
  <div className="dropdown-field" style={{ width: "100%", ...styleWrapper }}>
    <span className="dropdown-field__label">{label}</span>
    <Select id={id} value={value} onChange={onChange} style={style}>
      {dropdownItems.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
    {touched && error && <span>{error}</span>}
  </div>
);

DropdownField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  dropdownItems: PropTypes.array.isRequired,
  style: PropTypes.any,
  styleWrapper: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.any,
};

DropdownField.defaultProps = {
  styleWrapper: {},
};

export default DropdownField;
