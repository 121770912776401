import { bindActionCreators } from "redux";
import { compose } from "recompose";
import endpoints from "api/endpoints";
import {
  requestAsync,
  querySelectors,
} from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { user } from "api";

import { getUsers, getUsersMeta, getSuspicious } from "./selectors";

const UsersApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        users: getUsers(state),
        usersMeta: getUsersMeta(state),
        suspects: getSuspicious(state),

        usersIsFetching:
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getUsersUrl(),
          }) || false,
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getUsers: (requestParams) =>
              requestAsync(user.getUsers(requestParams)),
            putChangeUserRole: (requestParams) =>
              requestAsync(user.putChangeUserRole(requestParams)),
            putUserBlock: (requestParams) =>
              requestAsync(user.putUserBlock(requestParams)),
            putUserUnblock: (requestParams) =>
              requestAsync(user.putUserUnblock(requestParams)),
            getSuspicious: () =>
              requestAsync(user.getSuspicious()),
            putSuspectCancel: (requestParams) =>
              requestAsync(user.putSuspectCancel(requestParams))
          },
          dispatch
        ),
      })
    )
  )(WrappedComponent);

export default UsersApiHOC;
