import _ from "lodash";
import {
  actionTypes,
  updateResults,
  updateEntities,
} from "@digitalwing.co/redux-query-immutable";
import { token } from "helpers";
import Immutable from "immutable";
import { store } from "../configureStore";

export default () => (next) => (action) => {
  if (action.type === actionTypes.REQUEST_FAILURE) {
    if (action.status === 401) {
      // eslint-disable-next-line no-restricted-globals
      const val = confirm(
        "Authentication error: user session expired. Press Ok to log out or Cancel to continue."
      );
      if (val) {
        store.dispatch(updateEntities({ user: Immutable.Map() }));
        store.dispatch(updateResults({ user: "" }));
        token.removeToken();
      }
    } else {
      const { errorCallback } = action.meta;

      if (errorCallback && _.isFunction(errorCallback)) {
        errorCallback(action.responseBody);
      }
    }
  }
  return next(action);
};
