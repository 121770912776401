import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { offer } from "schemas";

/**
 *
 * @param id {String}
 * @param successCallback {Function}
 * @param errorCallback {Function}
 */
export default ({ id, successCallback, errorCallback }) => {
  return requestAsync({
    url: endpoints.getStartOfferByIdUrl(id),
    queryKey: endpoints.getStartOfferByIdUrl(id),
    transform: (response) =>
      normalize(response.data, offer.offerSchema).entities,
    transformResult: (response) => ({
      offer: normalize(response.data, offer.offerSchema).result,
    }),
    meta: {
      authToken: true,
      successCallback,
      errorCallback,
    },
    options: {
      headers: {
        Accept: "application/json",
      },
      method: "PUT",
    },
    update: {
      offer: (_, result) => result,
    },
    updateResult: {
      offer: (_, result) => result,
    },
  });
};
