import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { payment } from "schemas";

export const getPayment = createSelector(
  (state) => getResults(state).get("payment", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) =>
    denormalize(result, payment.arrayOfPaymentSchemas, entities)
);
