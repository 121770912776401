import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, FieldArray, reduxForm } from "redux-form/immutable";
import { required } from "validators";
import { ui } from "components";
import { Button, Grid } from "@material-ui/core";

const PaymentForm = ({ handleSubmit, type, tags, handleClose }) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="alias"
      props={{
        id: "alias",
        label: "alias",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="shortName"
      props={{
        id: "shortName",
        label: "Short Name",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="fullName"
      props={{
        id: "fullName",
        label: "Full Name",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="englishName"
      props={{
        id: "englishName",
        label: "English Name",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.ColorField}
      name="colorBg"
      props={{
        id: "colorBg",
        label: "Background Color",
      }}
    />
    <Field
      component={ui.Fields.ColorField}
      name="color"
      props={{
        id: "color",
        label: "Color",
      }}
    />
    <Field
      component={ui.Fields.ChipField}
      name="fiats"
      props={{
        tags,
      }}
    />
    <FieldArray component={ui.Fields.ConstructorField} name="kit" />
    <Field
      component={ui.Fields.ImageField}
      name="icon"
      props={{
        id: "icon",
        label: "icon",
      }}
    />
    <Grid spacing={4} container justifyContent="center" alignContent="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {type === "create" ? "CREATE" : "SAVE"}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          CLOSE
        </Button>
      </Grid>
    </Grid>
  </form>
);

PaymentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: "paymentForm",
  })
)(PaymentForm);
