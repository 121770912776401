import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

import "./style.scss";

const BaseField = ({
  input: { value, onChange },
  id,
  label,
  type,
  fullWidth,
  multiline,
  disabled,
  styleWrapper,
  style,
  meta: { touched, error },
}) => (
  <div style={{ width: "100%", ...styleWrapper, position: "relative" }}>
    <TextField
      id={id}
      label={label}
      value={value}
      type={type}
      onChange={onChange}
      fullWidth={fullWidth}
      multiline={multiline}
      disabled={disabled}
      style={style}
      margin="normal"
    />
    {touched && error && <span className="base-field__error">{error}</span>}
  </div>
);

BaseField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  styleWrapper: PropTypes.any,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.any,
};

BaseField.defaultProps = {
  type: "text",
  fullWidth: false,
  multiline: false,
  disabled: false,
  styleWrapper: {},
};

export default BaseField;
