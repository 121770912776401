import React from "react";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";
import { forms, apiHOCs } from "components";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const defaultFiat = {
  alias: "",
  shortName: "",
  fullName: "",
  ticker: "",
  minLimit: 0,
  maxLimit: 0,
  step: 0,
  digits: 0,
};

const FiatScreen = ({
  modal,
  setModal,
  fiat,
  postFiat,
  putFiat,
  initFiat,
  setInitFiat,
  selected,
  setSelected,
  deleteFiat,
  getFiat,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: "center",
        }}
      >
        Fiat
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {fiat.map((f) => (
          <ListItem
            key={f.get("id")}
            button
            onClick={() => {
              setInitFiat({
                alias: f.get("alias"),
                shortName: f.get("shortName"),
                fullName: f.get("fullName"),
                ticker: f.get("ticker"),
                minLimit: f.get("minLimit"),
                maxLimit: f.get("maxLimit"),
                step: f.get("step"),
                digits: f.get("digits"),
              });
              setSelected(f.get("id"));
              setModal(true);
            }}
          >
            <ListItemText
              primary={f.get("alias")}
              secondary={f.get("fullName")}
            />
            <IconButton
              style={{ marginLeft: 20 }}
              onClick={(e) => {
                e.stopPropagation();
                deleteFiat(f.get("id")).then(() => {
                  getFiat();
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
    >
      <Paper
        elevation={1}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          height: 500,
          overflow: "auto",
          padding: 20,
        }}
      >
        {modal && (
          <forms.FiatForm
            onSubmit={(body) =>
              selected === ""
                ? postFiat({
                    alias: body.get("alias"),
                    shortName: body.get("shortName"),
                    fullName: body.get("fullName"),
                    ticker: body.get("ticker"),
                    minLimit: +body.get("minLimit"),
                    maxLimit: +body.get("maxLimit"),
                    digits: +body.get("digits"),
                    step: +body.get("step"),
                  }).then((resp) => {
                    if (!resp.body.errorCode) {
                      setModal(false);
                    } else {
                      const err = JSON.stringify(resp.body.data);
                      alert(`postFiat error: ${err}`);
                      console.log(err);
                    }
                  })
                : putFiat({
                    alias: body.get("alias"),
                    shortName: body.get("shortName"),
                    fullName: body.get("fullName"),
                    ticker: body.get("ticker"),
                    minLimit: +body.get("minLimit"),
                    maxLimit: +body.get("maxLimit"),
                    digits: +body.get("digits"),
                    step: +body.get("step"),
                    fiatId: selected,
                  }).then((resp) => {
                    if (!resp.body.errorCode) {
                      setModal(false);
                    } else {
                      const err = JSON.stringify(resp.body.data);
                      alert(`putFiat error: ${err}`);
                      console.log(err);
                    }
                  })
            }
            handleClose={() => {
              setModal(false);
              setInitFiat({ ...defaultFiat });
              setSelected("");
            }}
            type={selected === "" ? "create" : "save"}
            initialValues={initFiat}
          />
        )}
      </Paper>
    </Modal>
  </div>
);

FiatScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  fiat: PropTypes.object.isRequired,
  postFiat: PropTypes.func.isRequired,
  putFiat: PropTypes.func.isRequired,
  initFiat: PropTypes.object.isRequired,
  setInitFiat: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  deleteFiat: PropTypes.func.isRequired,
  getFiat: PropTypes.func.isRequired,
};

export default compose(
  withState("modal", "setModal", false),
  withState("initFiat", "setInitFiat", { ...defaultFiat }),
  withState("selected", "setSelected", ""),
  apiHOCs.FiatApiHOC()
)(FiatScreen);
