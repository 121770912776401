import React from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, reduxForm, formValueSelector } from "redux-form/immutable";
import { greaterThanZero, required } from "validators";
import { ui } from "components";
import Button from "@material-ui/core/Button";

import "./style.scss";
import * as moment from "moment";

const TimerForm = ({ handleSubmit, currentTimerValue }) => {
  return (
    <form className="timer-form" autoComplete="off" onSubmit={handleSubmit}>
      <div className="timer-form__header">Edit Timer:</div>
      <div className="timer-form__body">
        <Field
          component={ui.Fields.BaseField}
          name="minutes"
          props={{
            id: "commissionPercentSell",
            label: "Minutes",
            type: "number",
            styleWrapper: { width: "auto" },
          }}
          validate={[required, greaterThanZero]}
        />
        <span className={"timer-form__body__time"}>
          (
          {moment
            .utc(
              moment.duration(+currentTimerValue, "minutes").asMilliseconds()
            )
            .format("HH:mm")}
          )
        </span>
        <Button
          variant="outlined"
          onClick={handleSubmit}
          style={{
            marginBottom: 8,
            width: 200,
          }}
        >
          {"SAVE"}
        </Button>
      </div>
    </form>
  );
};

TimerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

TimerForm.defaultProps = {
  initialValues: Immutable.Map(),
};

const selector = formValueSelector("timerForm");

export default compose(
  connect((state) => ({
    currentTimerValue: selector(state, "minutes"),
  })),
  reduxForm({
    form: "timerForm",
  })
)(TimerForm);
