import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { apiHOCs, ui } from "components";
import _ from "lodash";
import {
  generateRsaKeyPair,
  packPublicRsaKey,
} from "helpers/ChatEncryption/utils";
import decryptAesKey from "helpers/ChatEncryption/decryptAesKey";
import {
  compose,
  getContext,
  lifecycle,
  withProps,
  withState,
  withHandlers,
  withStateHandlers,
} from "recompose";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const ChatScreen = ({
  messages,
  getMessagesIsFetching,
  user,
  postMessage,
  postSystemMessage,
  postMessageIsFetching,
  chatId,
  aes,
  putResolveDeal,
  deal,
  isOpenConfirmModal,
  openConfirmModal,
  closeConfirmModal,
  confirmRequest,
  confirmInfo,
  isClosed,
}) => {
  useEffect(() => {
    if (messages.size && chatId) {
      // Отправляем системное сообщение о входе администратора в сделку
      const isUsersNotified = messages.find(
        (m) => m.get("body") === "administrator_joined_the_deal"
      );
      if (!isUsersNotified) {
        postSystemMessage({
          chatID: chatId,
          requestBody: {
            files: null,
            body: "administrator_joined_the_deal",
            ChannelId: chatId,
          },
        });
      }
    }
  }, [messages.size, chatId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Chat
        </Typography>
      </Paper>
      {getMessagesIsFetching ? (
        <ui.LoadingSpinner />
      ) : (
        <ui.Chat
          user={user}
          messages={messages}
          postMessage={postMessage}
          favorSeller={() =>
            openConfirmModal({
              id: chatId,
              role: "contragent",
            })
          }
          favorBuyer={() =>
            openConfirmModal({
              id: chatId,
              role: "agent",
            })
          }
          aes={aes}
          postMessageIsFetching={postMessageIsFetching}
          chatId={chatId}
          deal={deal}
          isClosed={isClosed}
          // isResolved={chat.get('isResolved')}
        />
      )}
      <Dialog
        open={isOpenConfirmModal}
        onClose={closeConfirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm action</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Close the dispute in favor of the "}
            <b>{confirmInfo.role}</b>
            {" ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmModal} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => confirmRequest(putResolveDeal(confirmInfo))}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ChatScreen.propTypes = {
  messages: PropTypes.object,
  getMessagesIsFetching: PropTypes.bool.isRequired,
  user: PropTypes.object,
  postMessage: PropTypes.func.isRequired,
  postSystemMessage: PropTypes.func.isRequired,
  postMessageIsFetching: PropTypes.bool.isRequired,
  chatId: PropTypes.string.isRequired,
  aes: PropTypes.string,
  putResolveDeal: PropTypes.func.isRequired,
  deal: PropTypes.func.isRequired,
  isOpenConfirmModal: PropTypes.bool.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  closeConfirmModal: PropTypes.func.isRequired,
  confirmRequest: PropTypes.func.isRequired,
  confirmInfo: PropTypes.object.isRequired,
};

export default compose(
  apiHOCs.DisputableDealsApiHOC(),
  apiHOCs.ChatApiHOC(),
  apiHOCs.AuthApiHOC(),
  withProps(({ match }) => ({
    chatId: _.get(match, "params.id"),
  })),
  withState("aes", "setAes", ""),
  withState("isClosed", "setClosed", false),

  withStateHandlers(
    { isOpenConfirmModal: false, confirmInfo: {} },
    {
      openConfirmModal: () => (confirmInfo = {}) => ({
        isOpenConfirmModal: true,
        confirmInfo,
      }),
      closeConfirmModal: () => () => ({
        isOpenConfirmModal: false,
        confirmInfo: {},
      }),
    }
  ),
  withHandlers({
    confirmRequest: ({ closeConfirmModal }) => (callback) => {
      if (typeof callback === "function") {
        callback();
      }

      closeConfirmModal();
    },
  }),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }),

  lifecycle({
    async componentDidMount() {
      const { chatId } = this.props;

      if (chatId) {
        const keyPair = await generateRsaKeyPair();

        const { body } = await this.props.getAesKey({
          key: await packPublicRsaKey(keyPair.publicKey),
          id: chatId,
        });

        if (body.data) {
          const aesKey = await decryptAesKey(body.data, keyPair.privateKey);
          this.props.setAes(aesKey);
        }

        this.props.getChat({ id: chatId }).then(({ body }) => {
          if (body.data.isClosed) {
            this.props.setClosed(true);
          } else {
            this.props.startWebsocket(chatId);
          }
        });
      }
    },
  })
)(ChatScreen);
