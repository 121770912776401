import Fields from "./Fields";
import LoadingSpinner from "./LoadingSpinner";
import NavBar from "./NavBar";
import Chat from "./Chat";
import Message from "./Message";
import UserProfile from "./UserProfile";
import SuspectInfo from "./SuspectInfo";
import ConfirmInfo from "./ConfirmInfo";

export default {
  Fields,
  LoadingSpinner,
  NavBar,
  Chat,
  Message,
  UserProfile,
  SuspectInfo,
  ConfirmInfo
};
