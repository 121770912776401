import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { fiat } from "schemas";

export const getFiat = createSelector(
  (state) => getResults(state).get("fiat", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, fiat.arrayOfFiatSchemas, entities)
);
