import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { createSelector } from "reselect";
import { denormalize } from "normalizr";
import { message, ticket } from "schemas";

export const getMessages = createSelector(
  (state) => getResults(state).get("messages", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, message.schemasArray, entities)
);

export const getTickets = createSelector(
  (state) => getResults(state).get("tickets", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, ticket.schemasArray, entities)
);
