import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";
import { forms, apiHOCs } from "components";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import endpoints from "../../api/endpoints";
import postPaymentFieldTranslations from "../../api/translations/postPaymentFieldTranslations";

const defaultPayment = {
  alias: "",
  shortName: "",
  fullName: "",
  englishName: "",
  colorBg: "#000000",
  color: "#000000",
  icon: "",
  fiats: [],
  kit: [],
};

const PaymentSystemScreen = ({
  payments,
  fiat,
  postPayment,
  putPayment,
  changeStatusPayment,
  deletePayment,
  getPayment,
}) => {
  const { i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [initPayment, setInitPayment] = useState({ ...defaultPayment });

  const submit = (values, dispatch, props) => {
    if (selected === "") {
      postPayment({
        ...values.toJS(),
        kit: JSON.stringify(values.toJS().kit.filter((field) => field.name)),
        fiats: values.toJS().fiats.join(","),
      }).then((resp) => {
        if (!resp.body.errorCode) {
          const { alias, kit } = values.toJS();

          postPaymentFieldTranslations({ paymentName: alias, kit, i18n });

          setModal(false);
        } else {
          const err = JSON.stringify(resp.body.data);
          alert(`postPayment error: ${err}`);
          console.log(err);
        }
      });
    } else {
      //обвновляем платежную систему
      putPayment({
        ...values.toJS(),
        paymentId: selected,
        kit: JSON.stringify(values.toJS().kit.filter((field) => field.name)),
        fiats: values.toJS().fiats.join(","),
      }).then((resp) => {
        const prevCustomFields = JSON.stringify(props.initialValues.toJS().kit);
        const nextCustomFields = JSON.stringify(values.toJS().kit);

        //если кастомные поля поменялись, отправляем запрос в сервис транслейтов
        if (prevCustomFields !== nextCustomFields) {
          const { alias, kit } = values.toJS();
          postPaymentFieldTranslations({ paymentName: alias, kit, i18n });
        }

        if (!resp.body.errorCode) {
          setModal(false);
        } else {
          const err = JSON.stringify(resp.body.data);
          alert(`putPayment error: ${err}`);
          console.log(err);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Payment
        </Typography>
      </Paper>
      <Paper
        elevation={1}
        style={{
          width: 820,
          padding: 10,
        }}
      >
        <List>
          {payments.map((payment) => (
            <ListItem
              key={payment.get("id")}
              button
              onClick={() => {
                setInitPayment({
                  alias: payment.get("alias"),
                  shortName: payment.get("shortName"),
                  fullName: payment.get("fullName"),
                  englishName: payment.get("englishName"),
                  colorBg: payment.get("colorBg"),
                  color: payment.get("color"),
                  icon: payment.get("icon"),
                  fiats: payment.get("fiats").split(","),
                  kit: JSON.parse(payment.get("kit")),
                });
                setSelected(payment.get("id"));
                setModal(true);
              }}
            >
              <ListItemText
                primary={payment.get("alias")}
                secondary={payment.get("fullName")}
              />
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  changeStatusPayment(payment.get("id"));
                }}
                color={payment.get("isActive") ? "secondary" : "primary"}
              >
                {payment.get("isActive") ? "DEACTIVATE" : "ACTIVATE"}
              </Button>
              <IconButton
                style={{ marginLeft: 20 }}
                onClick={(e) => {
                  e.stopPropagation();
                  deletePayment(payment.get("id")).then(() => {
                    getPayment();
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          onClick={() => setModal(true)}
          color="primary"
          fullWidth
        >
          ADD NEW
        </Button>
      </Paper>
      <Modal
        open={modal}
      >
        <Paper
          elevation={1}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            height: 500,
            overflow: "auto",
            padding: 20,
          }}
        >
          {modal && (
            <forms.PaymentForm
              tags={fiat.toJS().map((f) => ({ id: f.alias, title: f.alias }))}
              onSubmit={submit}
              type={selected === "" ? "create" : "save"}
              initialValues={initPayment}
              handleClose={() => {
                setModal(false);
                setInitPayment({ ...defaultPayment });
                setSelected("");
              }}
            />
          )}
        </Paper>
      </Modal>
    </div>
  );
};

PaymentSystemScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  payments: PropTypes.object.isRequired,
  fiat: PropTypes.object.isRequired,
  postPayment: PropTypes.func.isRequired,
  putPayment: PropTypes.func.isRequired,
  changeStatusPayment: PropTypes.func.isRequired,
  initPayment: PropTypes.object.isRequired,
  setInitPayment: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  deletePayment: PropTypes.func.isRequired,
  getPayment: PropTypes.func.isRequired,
};

export default compose(
  apiHOCs.PaymentApiHOC(),
  apiHOCs.FiatApiHOC()
)(PaymentSystemScreen);
