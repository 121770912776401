import React, { useState } from "react";
import { apiHOCs } from "components";
import { compose } from "recompose";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, CardContent, Collapse } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import DealInfoBlock from "../DealInfoBlock";

const Row = ({
  createdAt,
  creatorID,
  adminID,
  id,
  dealID,
  assign,
  close,
  isActive,
  isClose,
  chatID,
  showDealInfo,
  deal
}) => {
  const [expanded, setExpanded] = useState();
  const handleExpandClick = (id, event) => {
    event.stopPropagation();
    if (!expanded) {
      showDealInfo(id, () => {
        setExpanded(!expanded);
      });
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => handleExpandClick(dealID, e)}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {createdAt}
        </TableCell>
        <TableCell>
          {creatorID}
        </TableCell>
        <TableCell>
          {dealID}
        </TableCell>
        { (!adminID || isActive || isClose) &&
          <TableCell>
            <Button variant="contained"
              onClick={() => assign(isActive || isClose ? chatID : id)}>{isActive || isClose ? 'Go to chat' : 'Set on me'}</Button>
          </TableCell>
        }
        { isActive &&
          <TableCell>
            <Button variant="contained" color="primary"
              onClick={() => close(id)}>Close</Button>
          </TableCell>
        }
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <DealInfoBlock
                deal={deal}
              />
            </CardContent>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const TicketsTable = ({
  rows,
  assign,
  close,
  isActive,
  isClose,
  deal,
  getDeal
}) => {

  return (
    <Paper
      elevation={1}
      style={{
        width: 1120,
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Creator ID</TableCell>
              <TableCell>Deal ID</TableCell>
              <TableCell>Assign</TableCell>
              {isActive && (
                <TableCell>Close</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <Row
                  createdAt={row.get('createdAt')}
                  creatorID={row.get('creatorID')}
                  id={row.get('id')}
                  adminID={row.get('adminID')}
                  chatID={row.get('chatID')}
                  dealID={row.getIn(['attachedTo', 'id'])}
                  assign={assign}
                  close={close}
                  isActive={isActive}
                  isClose={isClose}
                  showDealInfo={(id, handler) => {
                    getDeal({ id }).then(() => handler());
                  }}
                  deal={deal}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default compose(
  apiHOCs.DealApiHOC(),
)(TicketsTable);