import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { bank as bankSchemas } from "schemas";

export default (
  {
    resultKey = "banks",
    alias,
    shortName,
    fullName,
    englishName,
    colorBg,
    color,
    fiats,
    icon,
    countries,
  } = { resultKey: "banks" }
) => ({
  url: endpoints.getBankUrl({ type: "extended" }),
  transform: (response) =>
    normalize(response.data, bankSchemas.bankSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, bankSchemas.bankSchema).result,
  }),
  queryKey: endpoints.getBankUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  body: {
    alias,
    shortName,
    fullName,
    englishName,
    colorBg,
    color,
    fiats,
    icon,
    countries,
  },
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  },
  update: {
    bank: (prevBank = Immutable.Map(), bank) => prevBank.mergeDeep(bank),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => prevResult.push(result),
  },
});
