import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form/immutable";
import { required, greaterThanZero } from "validators";
import { ui } from "components";
import { Button, Grid } from "@material-ui/core";

const FiatForm = ({ handleSubmit, type, handleClose }) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="alias"
      props={{
        id: "alias",
        label: "alias",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="shortName"
      props={{
        id: "shortName",
        label: "Short Name",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="fullName"
      props={{
        id: "fullName",
        label: "Full Name",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="ticker"
      props={{
        id: "ticker",
        label: "ticker",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="minLimit"
      props={{
        id: "minLimit",
        label: "min Limit",
        type: "number",
        fullWidth: true,
      }}
    />
    <Field
      component={ui.Fields.BaseField}
      name="maxLimit"
      props={{
        id: "maxLimit",
        label: "max Limit",
        type: "number",
        fullWidth: true,
      }}
      validate={[greaterThanZero]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="step"
      props={{
        id: "step",
        label: "step",
        type: "number",
        fullWidth: true,
      }}
      validate={[greaterThanZero]}
    />
    <Field
      component={ui.Fields.BaseField}
      name="digits"
      props={{
        id: "digits",
        label: "digits",
        type: "number",
        fullWidth: true,
      }}
      validate={[greaterThanZero]}
    />
    <Grid spacing={4} container justifyContent="center" alignContent="center">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {type === "create" ? "CREATE" : "SAVE"}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          CLOSE
        </Button>
      </Grid>
    </Grid>
  </form>
);

FiatForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: "fiatForm",
  })
)(FiatForm);
