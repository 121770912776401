import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { deal as dealSchema, deal } from "schemas";
import Immutable from "immutable";

export default ({ id, resultKey, successCallback, errorCallback }) => {
  return {
    url: endpoints.getDealByIdUrl(id),
    queryKey: endpoints.getDealByIdUrl(),
    transform: (response) => {
      const responseData = {
        ...response.data.deal,
        agent: response.data.agent,
        contragent: response.data.contragent,
      };
      return normalize(responseData, deal.dealSchema).entities;
    },
    transformResult: (response) => {
      const responseData = {
        ...response.data.deal,
        agent: response.data.agent,
        contragent: response.data.contragent,
      };
      const res = normalize(responseData, deal.dealSchema).result;
      return {
        deal: res,
        [resultKey]: [res],
      };
    },
    meta: {
      authToken: true,
      withoutErrorToast: true,
      successCallback,
      errorCallback,
    },
    force: true,
    options: {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
    },
    update: {
      deal: (prevEntities = Immutable.Map(), entities) =>
        prevEntities.mergeDeep(entities),
    },
    updateResult: {
      deal: (prevResult, result) => result,
      [resultKey]: (prevResult = Immutable.List(), result) =>
        prevResult.concat(result) || Immutable.List(),
    },
  };
};
