import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import { compose, getContext, lifecycle, withState } from "recompose";

import TicketsTable from "components/ui/TicketsTable";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const OpenPrivateChatsScreen = ({
  tickets,
  putAssignTicket,
  getTickets,
  onChangeOpenChatsCount
}) => {
  const openTickets = useMemo(() => {
    return tickets.filter((ticket) => {
      return !ticket.get('adminID');
    });
  }, [tickets]);

  useEffect(() => {
    onChangeOpenChatsCount(openTickets.size);
  }, [openTickets.size]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 1100,
          marginBottom: 20,
          padding: 10,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Start private chats
        </Typography>
      </Paper>
      <Paper
        elevation={1}
        style={{
          width: 1120,
        }}
      >
        <TicketsTable 
          rows={openTickets.sort((t1, t2) => {
            return new Date(t2.get('createdAt')) - new Date(t1.get('createdAt'));
          })}
          assign={(id) => putAssignTicket({
            id: id,
            successCallback: () => getTickets({closed: false})
          })}
        />
      </Paper>
    </div>
  );
}

OpenPrivateChatsScreen.defaultProps = {
  onChangeOpenChatsCount: PropTypes.func.isRequired
}

export default compose(
  apiHOCs.ChatApiHOC(),
  lifecycle({
    componentDidMount() {
      this.props.getTickets({closed: false});
    },
  })
)(OpenPrivateChatsScreen);