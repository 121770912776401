import endpoints from "api/endpoints";

export default ({ id, successCallback } = {}) => ({
  url: endpoints.getActionExchangeUrl(id),
  queryKey: endpoints.getActionExchangeUrl(id),
  meta: {
    authToken: true,
    successCallback
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "DELETE",
  },
});
