import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form/immutable";
import { lessThan, moreThan, required } from "validators";
import { ui } from "components";
import Button from "@material-ui/core/Button";

const lessThan100 = lessThan(100);
const moreThanNegative100 = moreThan(-100);

const ProfitPercentForm = ({ handleSubmit }) => {
  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <div className="auth-form__row">
        <div style={{ alignSelf: "center", width: 500 }}>Прямое флоу:</div>
        <Field
          component={ui.Fields.BaseField}
          name="minProfitPercentStraight"
          props={{
            id: "minProfitPercentStraight",
            label: "Min profit percent",
            type: "number",
          }}
          validate={[required, moreThanNegative100]}
        />
        <Field
          component={ui.Fields.BaseField}
          name="maxProfitPercentStraight"
          props={{
            id: "maxProfitPercentStraight",
            label: "Max profit percent",
            type: "number",
          }}
          validate={[required, lessThan100]}
        />
      </div>
      <div className="auth-form__row">
        <div style={{ alignSelf: "center", width: 500 }}>Свапнутое флоу:</div>
        <Field
          component={ui.Fields.BaseField}
          name="minProfitPercentSwapped"
          props={{
            id: "minProfitPercentSwapped",
            label: "Min profit percent",
            type: "number",
          }}
          validate={[required, moreThanNegative100]}
        />
        <Field
          component={ui.Fields.BaseField}
          name="maxProfitPercentSwapped"
          props={{
            id: "maxProfitPercentSwapped",
            label: "Max profit percent",
            type: "number",
          }}
          validate={[required, lessThan100]}
        />
      </div>
      <Button
        variant="outlined"
        type="submit"
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        SAVE
      </Button>
    </form>
  );
};

ProfitPercentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "profitPercentForm",
  enableReinitialize: true,
})(ProfitPercentForm);
