import React from "react";

import Button from "@material-ui/core/Button";

const ConfirmInfo = ({
	confirm,
	cancel
}) => {
	return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-around'
      }}>
        <Button onClick={confirm} color="primary">Confirm</Button>
        <Button onClick={cancel} color="secondary">Cancel</Button>
		  </div>
    </div>
	);
}

export default ConfirmInfo;