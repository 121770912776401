import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { isImage } from "helpers";

import "./style.scss";
import endpoints from "../../../api/endpoints";
import { getToken } from "../../../helpers/token";

const Message = ({ username, text, images, isSelf, isRead, date }) => (
  <Card
    style={{
      width: 450,
      position: "relative",
      margin: "10px 5px",
      padding: "10px 20px",
      alignSelf: isSelf ? "flex-end" : "flex-start",
      overflow: "initial",
      backgroundColor: isSelf && "#c1e1ff",
    }}
  >
    <Typography
      style={{
        marginBottom: 6,
        fontSize: 14,
        textAlign: isSelf ? "right" : "left",
        display: "flex",
        justifyContent: isSelf ? "flex-end" : "space-between",
      }}
      color="textSecondary"
    >
      {!isSelf && <span>{username}</span>}
      <span style={{ marginLeft: 50, fontSize: 12 }}>
        {moment(date).fromNow()}
      </span>
    </Typography>
    <Typography
      component="p"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {text}
      {images &&
        images.map((I) =>
          isImage(I.link) ? (
            <a
              href={`${endpoints.getFileBucketUrl("chat")}/${
                I.link
              }?token=${getToken()}`}
              target="_blank"
              rel="noopener noreferrer"
              download
              style={{ marginBottom: 10 }}
            >
              <img
                src={`${endpoints.getFileBucketUrl("chat")}/${
                  I.link
                }?token=${getToken()}`}
                alt="img"
                width="189"
              />
            </a>
          ) : (
            <a
              href={`${endpoints.getFileBucketUrl("chat")}/${
                I.link
              }?token=${getToken()}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ "text-decoration": "underline" }}
              download
            >
              {I.link}
            </a>
          )
        )}
    </Typography>
    {isRead && (
      <div
        style={{
          position: "absolute",
          top: 26,
          left: -22,
          width: 10,
          height: 10,
          borderRadius: "50%",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        }}
      />
    )}
  </Card>
);

Message.propTypes = {
  isRead: PropTypes.bool,
  isSelf: PropTypes.bool,
  text: PropTypes.string,
  images: PropTypes.object.isRequired,
  username: PropTypes.string,
  date: PropTypes.string,
};

export default Message;
