import getCommission from "./getCommission";
import postCommission from "./postCommission";
import putCommission from "./putCommission";
import changeStatusCommission from "./changeStatusCommission";
import deleteCommission from "./deleteCommission";

export default {
  getCommission,
  postCommission,
  putCommission,
  changeStatusCommission,
  deleteCommission,
};
