import React from "react";
import PropTypes from "prop-types";
import { compose, lifecycle } from "recompose";
import { ui, apiHOCs } from "components";

const Authentication = ({ getUserIsInitializing, children }) =>
  getUserIsInitializing ? <ui.LoadingSpinner fullSize /> : children;

Authentication.propTypes = {
  children: PropTypes.element.isRequired,
  getUserIsInitializing: PropTypes.bool.isRequired,
};

export default compose(
  apiHOCs.AuthApiHOC(),
  lifecycle({
    componentDidMount() {
      this.props.getUser();
    },
  })
)(Authentication);
