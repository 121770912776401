import AuthApiHOC from "./AuthApiHOC";
import DisputableDealsApiHOC from "./DisputableDealsApiHOC";
import BankApiHOC from "./BankApiHOC";
import CommissionApiHOC from "./CommissionApiHOC";
import CryptocurrencyApiHOC from "./CryptocurrencyApiHOC";
import FiatApiHOC from "./FiatApiHOC";
import PaymentApiHOC from "./PaymentApiHOC";
import ChatApiHOC from "./ChatApiHOC";
import UsersApiHOC from "./UsersApiHOC";
import ExchangeApiHOC from "./ExchangeApiHOC";
import DealApiHOC from "./DealApiHOC";
import OfferApiHOC from "./OfferApiHOC";
import ProfitLimitsApiHOC from "./ProfitLimitsApiHOC";

export default {
  AuthApiHOC,
  DisputableDealsApiHOC,
  BankApiHOC,
  CommissionApiHOC,
  CryptocurrencyApiHOC,
  FiatApiHOC,
  PaymentApiHOC,
  ChatApiHOC,
  UsersApiHOC,
  ExchangeApiHOC,
  DealApiHOC,
  OfferApiHOC,
  ProfitLimitsApiHOC,
};
