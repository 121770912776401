import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { fiat as fiatSchemas } from "schemas";

export default (
  {
    resultKey = "fiat",
    fiatId,
    alias,
    shortName,
    fullName,
    ticker,
    minLimit,
    maxLimit,
    step,
    digits,
  } = { resultKey: "fiat" }
) => ({
  url: endpoints.getActionFiatUrl(fiatId),
  transform: (response) =>
    normalize(response.data, fiatSchemas.fiatSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, fiatSchemas.fiatSchema).result,
  }),
  queryKey: endpoints.getActionFiatUrl(fiatId),
  meta: {
    authToken: true,
  },
  body: {
    alias,
    shortName,
    fullName,
    ticker,
    minLimit,
    maxLimit,
    step,
    digits,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    fiat: (prevFiat = Immutable.Map(), fiat) => prevFiat.mergeDeep(fiat),
  },
});
