import { bindActionCreators } from "redux";
import { compose, lifecycle, withHandlers } from "recompose";
import {
  querySelectors,
  requestAsync,
  updateEntities,
  updateResults,
} from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import Immutable from "immutable";
import { normalize } from "normalizr";
import ReconnectingWebSocket from "reconnecting-websocket";

import { chat } from "api";
import endpoints from "api/endpoints";
import { token } from "helpers";
import { message, ticket } from "schemas";
import { getEntities, getResults } from "reducers";
import { getMessages, getTickets } from "./selectors";

let ws;

const ChatApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        messagesEntities: getEntities(state).get("message", Immutable.Map()),
        messagesResults: getResults(state).get("messages", Immutable.List()),
        messages: getMessages(state),
        ticketsEntities: getEntities(state).get("tickets", Immutable.Map()),
        ticketsResults: getResults(state).get("tickets", Immutable.List()),
        tickets: getTickets(state),
        getMessagesIsFetching:
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getMessagesUrl(),
          }) ||
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getDealByIdUrl(),
          }) ||
          false,
        postMessageIsFetching:
          querySelectors.isPending(state.get("queries"), {
            queryKey: endpoints.getMessageUrl(),
          }) || false,
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getChat: (args) => requestAsync(chat.getChat(args)),
            getMessages: (args) => requestAsync(chat.getMessages(args)),
            postMessage: (requestBody, extraOptions) =>
              requestAsync(chat.postMessage(requestBody, extraOptions)),
            postSystemMessage: (chatID, requestBody, extraOptions) =>
              requestAsync(chat.postSystemMessage(chatID, requestBody, extraOptions)),
            getAesKey: (args) => requestAsync(chat.getAesKey(args)),
            updateMessagesEntities: (messagesEntities) =>
              updateEntities({ message: messagesEntities }),
            updateMessagesResults: (messagesResults) =>
              updateResults({ messages: messagesResults }),
            getTickets: (body) => requestAsync(chat.getTickets(body)),
            putAssignTicket: (args) => requestAsync(chat.putAssignTicket(args)),
            putCloseTicket:(args) => requestAsync(chat.putCloseTicket(args)),
            updateTicketsEntities: (ticketsEntities) =>
              updateEntities({ tickets: ticketsEntities }),
            updateTicketsResults: (ticketsResults) =>
              updateResults({ tickets: ticketsResults }),
          },
          dispatch
        ),
      })
    ),
    withHandlers({
      updateMessages: ({
        updateMessagesEntities,
        updateMessagesResults,
        messagesEntities,
        messagesResults,
      }) => ({ data }) => {
        const { entities, result } = normalize(data, message.schema);
        updateMessagesEntities(
          messagesEntities.mergeDeep(Immutable.fromJS(entities.message))
        );
        updateMessagesResults(messagesResults.push(result));
      },
      updateTickets: ({
        updateTicketsEntities,
        updateTicketsResults,
        ticketsEntities,
        ticketsResults,
      }) => ({ data }) => {
        const { entities, result } = normalize(data, ticket.schema);
        updateTicketsEntities(
          ticketsEntities.mergeDeep(Immutable.fromJS(entities.tickets))
        );
        updateTicketsResults(ticketsResults.push(result));
      },
    }),
    withHandlers({
      startWebsocket: ({ updateMessages, match }) => (id) => {
        ws = new ReconnectingWebSocket(
          endpoints.getChatSocketUrl(id, {
            token: token.getToken()
          }),
          [],
          {
            debug: process.env.NODE_ENV !== "production",
            connectionTimeout: 1000,
            maxReconnectionDelay: 1000,
            maxRetries: 10,
          }
        );

        ws.onmessage = (e) => {
          // e.data - строка в формате {"type":"new","payload":"{}"}
          const data = JSON.parse(e.data);
          updateMessages({data: data});
        };
      },
    }),
    lifecycle({
      componentWillUnmount() {
        if (ws) {
          ws.close();
        }
      },
    })
  )(WrappedComponent);

export default ChatApiHOC;
