import React from "react";

import Button from "@material-ui/core/Button";

const SuspectInfo = ({
	id,
	block,
	complete,
	cancel
}) => {
	return (
    <div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-around'
      }}>
        <Button onClick={complete} color="primary">Complete transactions</Button>
        <Button onClick={block} color="secondary">Ban user</Button>
        <Button onClick={cancel} color="primary">Cancel transactions</Button>
		  </div>
    </div>
	);
}

export default SuspectInfo;