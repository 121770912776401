import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, reduxForm } from "redux-form/immutable";
import { ui } from "components";
import Button from "@material-ui/core/Button";

const ContentForm = ({ handleSubmit, type }) => (
  <form className="auth-form" autoComplete="off">
    <Field
      component={ui.Fields.BaseField}
      name="title"
      props={{
        id: "title",
        label: "Title",
        fullWidth: true,
      }}
    />
    <Field component={ui.Fields.EditorField} name="content" />
    <Button
      variant="outlined"
      onClick={handleSubmit}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      {type === "create" ? "CREATE" : "SAVE"}
    </Button>
  </form>
);

ContentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default compose(
  reduxForm({
    form: "contentForm",
  })
)(ContentForm);
