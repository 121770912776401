import { bindActionCreators } from "redux";
import { compose, lifecycle } from "recompose";
import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { cryptocurrency as cryptocurrencyApi } from "api";

import { getCrypto } from "./selectors";

const CryptocurrencyApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        cryptocurrency: getCrypto(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getCrypto: () => requestAsync(cryptocurrencyApi.getCrypto()),
            postCrypto: (body) =>
              requestAsync(cryptocurrencyApi.postCrypto({ ...body })),
            putCrypto: (body) =>
              requestAsync(cryptocurrencyApi.putCrypto({ ...body })),
            changeStatusCrypto: (cryptoId) =>
              requestAsync(cryptocurrencyApi.changeStatusCrypto({ cryptoId })),
            deleteCrypto: (cryptoId) =>
              requestAsync(cryptocurrencyApi.deleteCrypto({ cryptoId })),
          },
          dispatch
        ),
      })
    ),
    lifecycle({
      componentDidMount() {
        this.props.getCrypto();
      },
    })
  )(WrappedComponent);

export default CryptocurrencyApiHOC;
