import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { commission } from "schemas";

export default (
  {
    resultKey = "commission",
    symbol,
    tickerSell,
    tickerBuy,
    commissionPercentSell,
    commissionPercentBuy,
  } = { resultKey: "commission" }
) => ({
  url: endpoints.getCommissionUrl(),
  transform: (response) =>
    normalize(response.data, commission.commissionSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, commission.commissionSchema).result,
  }),
  queryKey: endpoints.getCommissionUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  body: {
    symbol,
    tickerSell,
    tickerBuy,
    commissionPercentSell,
    commissionPercentBuy,
  },
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  },
  update: {
    commission: (prevCommission = Immutable.Map(), result) =>
      prevCommission.mergeDeep(result),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => prevResult.push(result),
  },
});
