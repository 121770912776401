import { bindActionCreators } from "redux";
import { compose, lifecycle } from "recompose";
import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { bank as bankApi } from "api";

import { getBanks } from "./selectors";

const BankApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        banks: getBanks(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getBanks: () => requestAsync(bankApi.getBanks()),
            postBank: (body) => requestAsync(bankApi.postBank({ ...body })),
            putBank: (body) => requestAsync(bankApi.putBank({ ...body })),
            changeStatusBank: (bankId) =>
              requestAsync(bankApi.changeStatusBank({ bankId })),
            deleteBank: (bankId) =>
              requestAsync(bankApi.deleteBank({ bankId })),
          },
          dispatch
        ),
      })
    ),
    lifecycle({
      componentDidMount() {
        this.props.getBanks();
      },
    })
  )(WrappedComponent);

export default BankApiHOC;
