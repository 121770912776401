import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { bank as bankSchemas } from "schemas";

export default ({ resultKey = "bank", bankId } = { resultKey: "bank" }) => ({
  url: endpoints.getChangeStatusBankUrl(bankId),
  transform: (response) =>
    normalize(response.data, bankSchemas.bankSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, bankSchemas.bankSchema).result,
  }),
  queryKey: endpoints.getChangeStatusBankUrl(bankId),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    bank: (prevBank = Immutable.Map(), bank) => prevBank.mergeDeep(bank),
  },
});
