import { bindActionCreators } from "redux";
import { compose } from "recompose";

import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { profitLimits } from "api";

import { getProfitLimits } from "./selectors";

const ProfitLimitsApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        profitLimits: getProfitLimits(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getProfitLimits: () => requestAsync(profitLimits.getProfitLimits()),
            putProfitLimits: (body) =>
              requestAsync(profitLimits.putProfitLimits({ ...body })),
          },
          dispatch
        ),
      })
    )
  )(WrappedComponent);

export default ProfitLimitsApiHOC;
