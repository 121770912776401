import { bindActionCreators } from "redux";
import { compose, lifecycle } from "recompose";
import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { payment as paymentApi } from "api";

import { getPayment } from "./selectors";

const PaymentApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        payments: getPayment(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getPayment: () => requestAsync(paymentApi.getPayment()),
            postPayment: (body) =>
              requestAsync(paymentApi.postPayment({ ...body })),
            putPayment: (body) =>
              requestAsync(paymentApi.putPayment({ ...body })),
            changeStatusPayment: (paymentId) =>
              requestAsync(paymentApi.changeStatusPayment({ paymentId })),
            deletePayment: (paymentId) =>
              requestAsync(paymentApi.deletePayment({ paymentId })),
          },
          dispatch
        ),
      })
    ),
    lifecycle({
      componentDidMount() {
        this.props.getPayment();
      },
    })
  )(WrappedComponent);

export default PaymentApiHOC;
