import endpoints from "api/endpoints";

export default (
  { resultKey = "isCryptocurrencyDeleted", cryptoId } = {
    resultKey: "isCryptocurrencyDeleted",
  }
) => ({
  url: endpoints.getActionCryptoUrl(cryptoId),
  transform: (response) => !response.error,
  transformResult: (response) => ({
    [resultKey]: !response.error,
  }),
  queryKey: endpoints.getActionCryptoUrl(cryptoId),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "DELETE",
  },
  update: {},
  updateResult: {
    [resultKey]: (prevResult, result) => result,
  },
});
