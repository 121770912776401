import AuthForm from "./AuthForm";
import TechnicalBreakForm from "./TechnicalBreakForm";
import ContentForm from "./ContentForm";
import FaqForm from "./FaqForm";
import CryptoForm from "./CryptoForm";
import FiatForm from "./FiatForm";
import BankForm from "./BankForm";
import DealAndOfferForm from "./DealAndOfferForm";
import PaymentForm from "./PaymentForm";
import CommissionForm from "./CommissionForm";
import ExchangeForm from "./ExchangeForm";
import ProfitPercentForm from "./ProfitPercentForm";
import TimerForm from "./TimerForm";

export default {
  AuthForm,
  TechnicalBreakForm,
  ContentForm,
  FaqForm,
  CryptoForm,
  FiatForm,
  BankForm,
  PaymentForm,
  CommissionForm,
  ExchangeForm,
  DealAndOfferForm,
  ProfitPercentForm,
  TimerForm,
};
