import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { offer } from "schemas";

export const getOffer = createSelector(
  (state) => getResults(state).get("offer", Immutable.Map()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, offer.offerSchema, entities)
);
