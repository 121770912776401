import getBanks from "./getBanks";
import postBank from "./postBank";
import putBank from "./putBank";
import changeStatusBank from "./changeStatusBank";
import deleteBank from "./deleteBank";

export default {
  getBanks,
  postBank,
  putBank,
  changeStatusBank,
  deleteBank,
};
