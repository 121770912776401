import React from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import { compose, getContext, lifecycle, withState } from "recompose";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import VerticalTabs from "../../components/ui/VerticalTabs";

const DisputesScreen = ({ onChangeOpenDisputesCount }) => (
  <div
    style={{
      padding: "20px",
      maxWidth: "1280px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    }}
  >
    <VerticalTabs onChangeOpenDisputesCount={onChangeOpenDisputesCount} />
  </div>
);

DisputesScreen.propTypes = {
  onChangeOpenDisputesCount: PropTypes.func.isRequired,
  disputableDeals: PropTypes.object,
  putConfirmAdmin: PropTypes.func,
  dealId: PropTypes.string,
  setDealId: PropTypes.func,
  router: PropTypes.any,
};

export default compose(apiHOCs.DealApiHOC())(DisputesScreen);
