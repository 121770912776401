import { unpack, importAesGcmKey } from './utils';

const decode = (byteStream) => {
  const decoder = new TextDecoder();
  return decoder.decode(byteStream);
};

/**
 * Функция для дешифровки сообщения
 *
 * @param message {String} Сообщение для дешифровки в base64
 * @param key {ArrayBuffer} Ключ
 * @param strIV {String} Вектор инициализации в base64
 * @return {Promise<String>} Строку с дешифрованым сообщением
 */
export default async (message, key, strIV) => {
    const cipher = unpack(message);
    const iv = unpack(strIV);
    const cryptoKey = await importAesGcmKey(key);

    const encoded = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv,
      },
      cryptoKey,
      cipher,
    );

    return decode(encoded);
};
