import React from "react";
import { useState, useEffect } from "react";
import { compose, getContext, withHandlers, withState } from "recompose";
import { apiHOCs, ui } from "components";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import encryptMessage from "helpers/ChatEncryption/encryptMessage";
import decryptMessage from "helpers/ChatEncryption/decryptMessage";

import { parseJson, fileUpload } from "helpers";
import TextField from "@material-ui/core/TextField";
import endpoints from "api/endpoints";
import { getToken } from "helpers/token";

import "./style.scss";

const Chat = ({
  user,
  messages,
  chatMessage,
  setChatMessage,
  sendMessage,
  sendFile,
  postMessageIsFetching,
  selectedFile,
  setSelectedFile,
  aes,
  favorSeller,
  favorBuyer,
  isResolved,
  isClosed,
  deal,
  getUsername,
  getFiles,
}) => {
  const [decryptedMessages, setDecryptedMessages] = useState([]);

  useEffect(() => {
    const safeMsgDecrypting = async () => {
      if (!aes) {
        return;
      }

      const res = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const message of messages.reverse()) {
        let newMessage = message;
        if (message.get("creatorID")) {
          const msg = message.get("body");
          const iv = message.get("iv");

          if (!msg || !iv) {
            newMessage = message.set("body", "");
          }

          try {
            // eslint-disable-next-line no-await-in-loop
            newMessage = message.set(
              "body",
              await decryptMessage(msg, aes, iv)
            );
          } catch {
            newMessage = message.set("body", "");
          }
        }

        res.push(newMessage);
      }
      setDecryptedMessages(res);
    };

    safeMsgDecrypting();
  }, [messages.size, aes]);

  return (
    <Paper
      elevation={1}
      style={{
        width: 820,
        height: "75vh",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          height: "70vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
          }}
        >
          <div
            style={{
              width: 760,
              height: "55vh",
              minHeight: "55vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
              padding: "10px 30px 0 30px",
            }}
          >
            {decryptedMessages.map((M) => (
              <ui.Message
                key={M.get("id")}
                isSelf={M.get("creatorID") === user.get("id")}
                text={M.get("body")}
                images={M.get("files") ? getFiles(M.get("files")) : []}
                username={getUsername(M)}
                isRead={M.get("isRead")}
                date={M.get("createdAt")}
              />
            ))}
          </div>
        </div>
      </div>
      {!isClosed && (
        <div
          style={{
            padding: 10,
            display: "flex",
            position: "relative",
          }}
        >
          <label htmlFor="file">Choose a file</label>
          {selectedFile && (
            <img
              src={`${endpoints.getFileBucketUrl("chat")}/${
                parseJson(selectedFile).link
              }?token=${getToken()}`}
              height="200"
              alt="icon"
              style={{
                position: "absolute",
                maxHeight: 25,
                top: -20,
              }}
              onClick={() => {
                setSelectedFile("");
              }}
            />
          )}
          <TextField
            fullWidth
            style={{
              alignSelf: "flex-end",
            }}
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
          />
          <input
            id="file"
            type="file"
            onChange={sendFile}
            className="inputfile"
          />
          {deal.get("id") && (
            <>
              <Button onClick={favorSeller} size="small">
                Favor contragent
              </Button>
              <Button onClick={favorBuyer} size="small">
                Favor agent
              </Button>
            </>
          )}
        </div>
      )}
      {!isResolved && (
        <Button
          onClick={sendMessage}
          size="small"
          disabled={
            (!chatMessage.length && !selectedFile.length) ||
            postMessageIsFetching
          }
        >
          {postMessageIsFetching ? <ui.LoadingSpinner small /> : "Send"}
        </Button>
      )}
    </Paper>
  );
};

Chat.propTypes = {
  user: PropTypes.object,
  messages: PropTypes.object,
  deal: PropTypes.object.isRequired,
  chatId: PropTypes.string.isRequired,
  chatMessage: PropTypes.string,
  setChatMessage: PropTypes.func,
  sendMessage: PropTypes.func,
  sendFile: PropTypes.func.isRequired,
  favorSeller: PropTypes.func.isRequired,
  favorBuyer: PropTypes.func.isRequired,
  postMessageIsFetching: PropTypes.bool,
  aes: PropTypes.string.isRequired,
  selectedFile: PropTypes.string.isRequired,
  isResolved: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,
  decypherMessage: PropTypes.func.isRequired,
};

export default compose(
  apiHOCs.AuthApiHOC(),

  withState("chatMessage", "setChatMessage", ""),
  withState("selectedFile", "setSelectedFile", ""),
  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }),

  withHandlers({
    uploadFile: ({ setSelectedFile }) => async (file) => {
      fileUpload(
        file,
        {
          sendCallback: (data) => {
            if (!data.error) {
              setSelectedFile(
                `{"name":"${file.name}","link":"${data.urls[0]}","size":"${file.size}"}`
              );
            }
          },
        },
        "chat"
      );
    },
    getUsername: ({ deal }) => ({ creatorID }) => {
      if (deal.get("id")) {
        return creatorID === deal.get("agentID")
          ? `${deal.getIn(["agent", "username"])} (agent)`
          : `${deal.getIn(["contragent", "username"])} (contragent)`;
      } else {
        return "";
      }
    },
    getFiles: () => (files) => {
      const data = files.toJS();
      try {
        return [JSON.parse(data)];
      } catch (e) {
        return data.filter((file) => file !== "");
      }
    },
  }),

  withHandlers({
    sendMessage: ({
      chatMessage,
      postMessage,
      setChatMessage,
      setSelectedFile,
      aes,
      chatId,
      selectedFile,
    }) => async () => {
      if (!aes) {
        return null;
      }

      let body = "";
      let iv = "";
      try {
        ({ body, iv } = await encryptMessage(chatMessage, aes));
      } catch (e) {
        alert(`failed ecnrypt chat message: ${e.message}`);
        return null;
      }

      return postMessage({
        id: chatId,
        requestBody: {
          files: selectedFile ? [selectedFile] : [],
          body,
          iv,
        },
        successCallback: () => {
          setChatMessage("");
          setSelectedFile("");
        },
      });
    },
  }),

  withHandlers({
    sendFile: ({ uploadFile }) => (e) => {
      const file = e.target.files[0];
      uploadFile(file);
    },
  })
)(Chat);
