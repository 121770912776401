import getExchange from "./getExchange";
import putExchange from "./putExchange";
import changeStatusExchange from "./changeStatusExchange";
import deleteExchange from "./deleteExchange";

export default {
  getExchange,
  putExchange,
  changeStatusExchange,
  deleteExchange,
};
