import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { exchange } from "schemas";

export default ({ resultKey = "exchange", exchangeId } = {}) => ({
  url: endpoints.getChangeStatusExchangeUrl(exchangeId),
  transform: (response) =>
    normalize(response.data, exchange.exchangeSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, exchange.exchangeSchema).result,
  }),
  queryKey: endpoints.getChangeStatusExchangeUrl(exchangeId),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    exchange: (prevExchange = Immutable.Map(), nextExchange) =>
      prevExchange.mergeDeep(nextExchange),
  },
});
