import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { commission } from "schemas";

export const getCommission = createSelector(
  (state) => getResults(state).get("commission", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) =>
    denormalize(result, commission.arrayOfCommissionSchemas, entities)
);
