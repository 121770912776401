import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import { compose, getContext, lifecycle } from "recompose";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TableSkeleton from "../../components/ui/Skeletons/TableSkeleton";
import StickyHeadTable from "../../components/ui/StickyHeadTable";
import moment from "moment/moment";

const ClosedDisputableDealsScreen = ({
  shortClosedDisputableDeals,
  shortClosedDisputableDealsMeta: meta,
  getDeal,
  disputableDeals,
  getClosedDisputableDealsIsFetching,
  user,
  getShortClosedDisputableDeals,
}) => {
  useEffect(() => {
    getShortClosedDisputableDeals({
      limit: 10,
      page: 1,
      adminID: user.get("id"),
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    getShortClosedDisputableDeals({
      limit: 10,
      page: newPage + 1,
      adminID: user.get("id"),
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Closed disputes
        </Typography>
      </Paper>
      {getClosedDisputableDealsIsFetching ? (
        <TableSkeleton width={820} />
      ) : (
        <Paper
          elevation={1}
          style={{
            width: 820,
          }}
        >
          <StickyHeadTable
            deals={disputableDeals}
            onChangePage={handleChangePage}
            showDealInfo={(id) => {
              getDeal({ id, resultKey: "disputableDeals" });
            }}
            columns={[
              { id: "number", label: "#" },
              { id: "id", label: "ID" },
              { id: "createdAt", label: "Дата создания" },
            ]}
            rows={shortClosedDisputableDeals
              .sort(
                (prev, next) =>
                  new Date(next.get("createdAt")) -
                  new Date(prev.get("createdAt"))
              )
              .map((deal) => ({
                number: deal.get("serialNumber"),
                id: deal.get("id"),
                createdAt: moment(deal.get("createdAt")).format(
                  "MMMM Do YYYY, h:mm:ss a"
                ),
              }))}
            meta={meta}
          />
        </Paper>
      )}
    </div>
  );
};

ClosedDisputableDealsScreen.propTypes = {
  shortClosedDisputableDeals: PropTypes.object,
  router: PropTypes.object,
};

export default compose(
  apiHOCs.DisputableDealsApiHOC(),
  apiHOCs.AuthApiHOC(),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  })
)(ClosedDisputableDealsScreen);
