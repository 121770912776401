export const required = (value) =>
  value || typeof value === "number" ? undefined : "Обязательное поле";

export const greaterThanZero = (value) =>
  value > 0 ? undefined : "Значение должно быть больше нуля";

export const lessThan = (max) => (value) =>
  value >= max ? `Значение должно быть меньше ${max}` : undefined;

export const moreThan = (min) => (value) =>
  value <= min ? `Значение должно быть больше ${min}` : undefined;
