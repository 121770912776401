import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { user } from "schemas";

export default ({ resultKey = "user" }) => ({
  url: endpoints.getUserUrl(),
  transform: (response) => normalize(response.data, user.schema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, user.schema).result,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  queryKey: endpoints.getUserUrl(),
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    user: (prevResult = Immutable.Map(), result) =>
      prevResult.mergeDeep(result),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => result,
  },
});
