import React from "react";
import PropTypes from "prop-types";
import { compose, lifecycle, withState, withStateHandlers } from "recompose";
import { apiHOCs, ui } from "components";
import moment from "moment";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";

const UsersScreen = ({
  users,
  usersMeta,
  getUsers,
  putChangeUserRole,
  modal,
  setModal,
  initUser,
  setInitUser,
  selected,
  setSelected,
  putUserBlock,
  setSelectedDate,
  selectedDate,
  setSearchText,
  searchText,
  putUserUnblock,
  blockedUser,
  setBlockedUser,
  searchUsers,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: "center",
        }}
      >
        Users
      </Typography>
      <div style={{ display: "flex" }}>
        <TextField
          label="search user"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onBlur={searchUsers}
          fullWidth
          margin="normal"
        />
        <Button onClick={searchUsers}>Find user</Button>
      </div>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {users.map((user) => (
          <ListItem
            key={user.get("id")}
            button
            onClick={(e) => {
              if (e.target && e.target.getAttribute("role") !== "button") {
                setSelected(user.get("id"));
                setBlockedUser(user.get("isBlocked"));
                setInitUser({
                  bannedUntil: user.get("bannedUntil"),
                  lastName: user.get("lastName"),
                  isBlocked: user.get("isBlocked"),
                  cryptoPriority: user.get("cryptoPriority"),
                  lastBannedAt: user.get("lastBannedAt"),
                  is2faActive: user.get("twofa").get("is2faActive"),
                  basicExchange: user.get("basicExchange"),
                  safety: user.get("safety"),
                  lang: user.get("lang"),
                  banReason: user.get("banReason"),
                  bannedBy: user.get("bannedBy"),
                  avatar: user.get("avatar"),
                  username: user.get("username"),
                  role: user.get("role"),
                  rating: user.get("rating"),
                  online: user.get("online"),
                  registrationDate: user.get("createdAt"),
                  isConfirm: user.get("isConfirm"),
                  language: user.get("language"),
                  firstName: user.get("firstName"),
                  selectedCrypto: user.get("selectedCrypto"),
                  email: user.get("email"),
                  description: user.get("description"),
                  basicCurrency: user.get("basicCurrency"),
                });
                setModal(true);
              }
            }}
          >
            <ListItemText
              primary={`Username: ${user.get("username")} Id: ${user.get(
                "id"
              )}`}
              secondary={moment(user.get("createdAt")).format(
                "MMMM Do YYYY, HH:mm:ss"
              )}
            />
            <Select
              value={user.get("role")}
              onChange={(value) =>
                putChangeUserRole({
                  id: user.get("id"),
                  role: value.target.value,
                })
              }
            >
              <MenuItem value="default">Default</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="moderator">Moderator</MenuItem>
            </Select>
          </ListItem>
        ))}
      </List>
      {usersMeta && usersMeta.get("total") > usersMeta.get("page") && (
        <Button
          variant="contained"
          onClick={() =>
            getUsers({
              page: usersMeta.get("page") + 1,
              count: 10,
              data: searchText,
            }).then((resp) => {
              if (resp.body.error) {
                alert("getUsers error");
                console.log(JSON.stringify(resp.body.data));
              }
            })
          }
          color="primary"
          fullWidth
        >
          SHOW MORE
        </Button>
      )}
    </Paper>
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setInitUser({});
      }}
    >
      <Paper
        elevation={1}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          maxHeight: 500,
          padding: 20,
          overflow: "auto",
        }}
      >
        {modal && (
          <ui.UserProfile
            {...initUser}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            onSubmit={(body) =>
              blockedUser
                ? putUserUnblock({
                    id: selected,
                  }).then((result) => {
                    if (!result.body.error) {
                      setModal(false);
                    } else {
                      setModal(false);
                      alert("block error");
                      console.log(JSON.stringify(result.body.data));
                    }
                  })
                : putUserBlock({
                    id: selected,
                    reason: body.get("blockReason"),
                    bannedAt: moment().format(),
                    bannedUntil: moment(selectedDate).format(),
                  }).then((result) => {
                    if (!result.body.error) {
                      setModal(false);
                    } else {
                      setModal(false);
                      alert("block error");
                      console.log(JSON.stringify(result.body.data));
                    }
                  })
            }
          />
        )}
      </Paper>
    </Modal>
  </div>
);

UsersScreen.propTypes = {
  users: PropTypes.object.isRequired,
  usersMeta: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  putChangeUserRole: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  initUser: PropTypes.object.isRequired,
  setInitUser: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  putUserBlock: PropTypes.func.isRequired,
  selectedDate: PropTypes.any.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  putUserUnblock: PropTypes.func.isRequired,
  blockedUser: PropTypes.func.isRequired,
  setBlockedUser: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
};

export default compose(
  apiHOCs.UsersApiHOC(),

  withState("modal", "setModal", false),
  withState("initUser", "setInitUser", {}),
  withState("selected", "setSelected", ""),
  withState("selectedDate", "setSelectedDate", new Date()),
  withState("searchText", "setSearchText", ""),
  withState("blockedUser", "setBlockedUser", false),

  withStateHandlers(({ searchText }) => ({ lastSearchText: searchText }), {
    // запрос сработает только если были изменения
    searchUsers: ({ lastSearchText }, { searchText, getUsers }) => () => {
      if (lastSearchText === searchText) {
        return {};
      }

      getUsers({
        page: 1,
        count: 10,
        data: searchText,
      }).then((resp) => {
        if (resp.body.error) {
          alert("getFilteredUsers error");
          console.log(JSON.stringify(resp.body.data));
        }
      });

      return {
        lastSearchText: searchText,
      };
    },
  }),

  lifecycle({
    componentDidMount() {
      this.props.getUsers({
        page: 1,
        count: 10,
      });
    },
  })
)(UsersScreen);
