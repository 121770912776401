export default (string) => {
  if (!string) {
    return null;
  }

  try {
    return JSON.parse(string);
  } catch (e) {
    return null;
  }
};
