import React, { useCallback } from "react";
import { compose, lifecycle, withState } from "recompose";
import { apiHOCs, ui } from "components";
import { actions } from "consts";
import moment from "moment";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";

const SuspiciousScreen = ({
  suspects,
  getSuspicious,
  modal,
  setModal,
  confirm,
  setConfirm,
  action,
  setAction,
  initUser,
  setInitUser,
  putUserBlock,
  putSuspectCancel
}) => {
  const confirmHandler = useCallback(() => {
    switch(action) {
      case actions.BAN_USER:
        putUserBlock(initUser.id);
        break;
      case actions.CANCEL_TRANSACTIONS:
        putSuspectCancel({id: initUser.id, isCancel: true});
        break;
      case actions.CONFIRM_TRANSACTIONS:
        putSuspectCancel({id: initUser.id, isCancel: false});
        break;
    }
    setInitUser({});
    setAction('');
    setConfirm(false);
    getSuspicious();
  }, [action])


  const onClose = (action) => {
    return () => {
      setModal(false);
      setConfirm(true);
      // Устанавливаем обработчик подтверждения операции админом
      setAction(action);
    }
  }

  const onConfirmClose = () => {
    setConfirm(false);
    setModal(true);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Suspicious users
        </Typography>
        <List>
          {suspects.map((user) => (
            <ListItem
              button
              key={user.get("userID")}
              onClick={(e) => {
                if (e.target && e.target.getAttribute("role") !== "button") {
                  const id = user.get("userID");
                  setInitUser({
                    id,
                    block: onClose(actions.BAN_USER),
                    complete: onClose(actions.CONFIRM_TRANSACTIONS),
                    cancel: onClose(actions.CANCEL_TRANSACTIONS)
                  });
                  setModal(true);
                }
              }
            }
            >
              <ListItemText
              primary={`Username: ${user.get("username")} Id: ${user.get(
                "userID"
              )}`}
              secondary={`Last suspected: ${moment(user.get("lastSuspected")).format(
                "MMMM Do YYYY, HH:mm:ss"
              )}`}
              />
              <ListItemText style={{textAlign: 'right'}}
              primary={`Suspendend transactions count: ${user.get("suspendedWithdrawTransactionsCount")}`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setInitUser({});
      }}
      >
        <Paper
        elevation={1}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          maxHeight: 500,
          padding: 20,
          overflow: "auto",
        }}
        >
          {modal && (
            <ui.SuspectInfo {...initUser} />
          )}
        </Paper>
      </Modal>
      <Modal
        open={confirm}
        onClose={onConfirmClose}
      >
        <Paper
        elevation={1}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxHeight: 500,
          padding: 20,
          overflow: "auto",
        }}
        >
          {confirm && (
            <ui.ConfirmInfo confirm={confirmHandler} cancel={onConfirmClose} />
          )}
        </Paper>
      </Modal>
    </div>
  )
}

export default compose(
  apiHOCs.UsersApiHOC(),

  withState("modal", "setModal", false),
  withState("initUser", "setInitUser", {}),
  withState("confirm", "setConfirm", false),
  withState("action", "setAction", ''),

  lifecycle({
    componentDidMount() {
      this.props.getSuspicious();
    },
  })
)(SuspiciousScreen);