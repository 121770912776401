import endpoints from "api/endpoints";

export default ({ id, resultKey = "shortDisputableDeals" }) => ({
  url: endpoints.getSetAdminUrl({ dealId: id }),
  queryKey: endpoints.getSetAdminUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: () => {},
  updateResult: {
    [resultKey]: (prevResult) => prevResult.filter((item) => item !== id),
  },
});
