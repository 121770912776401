import React from "react";
import PropTypes from "prop-types";
import { compose, withState, withProps } from "recompose";
import { forms, apiHOCs } from "components";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { commissions } from "../../consts";

const defaultCommission = {
  symbol: "",
  tickerSell: "",
  tickerBuy: "",
  commissionPercentSell: 0,
  commissionPercentBuy: 0,
};

const CommissionScreen = ({
  modal,
  setModal,
  commission,
  postCommission,
  putCommission,
  initCommission,
  setInitCommission,
  selected,
  setSelected,
  changeStatusCommission,
  deleteCommission,
  getCommission,
  currenciesList,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: "center",
        }}
      >
        Commission
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
        padding: 10,
      }}
    >
      <List>
        {commission.map((C) => (
          <ListItem
            key={C.get("id")}
            button
            onClick={() => {
              setInitCommission({
                tickerBuy: C.get("tickerBuy"),
                tickerSell: C.get("tickerSell"),
                commissionPercentSell: C.get("commissionPercentSell"),
                commissionPercentBuy: C.get("commissionPercentBuy"),
              });
              setSelected(C.get("id"));
              setModal(true);
            }}
          >
            <ListItemText
              primary={C.get("symbol")}
              secondary={`${C.get("commissionPercentSell")} -> ${C.get(
                "commissionPercentBuy"
              )}`}
            />
            {C.get("symbol") !== commissions.ALL_ALL &&
              C.get("symbol") !== commissions.CRYPTO_CRYPTO && (
                <div>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      changeStatusCommission(C.get("id"));
                    }}
                    color={C.get("isActive") ? "secondary" : "primary"}
                  >
                    {C.get("isActive") ? "DEACTIVATE" : "ACTIVATE"}
                  </Button>
                  <IconButton
                    style={{ marginLeft: 20 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteCommission(C.get("id")).then(() => {
                        getCommission();
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        onClick={() => setModal(true)}
        color="primary"
        fullWidth
      >
        ADD NEW
      </Button>
    </Paper>
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setInitCommission({ ...defaultCommission });
        setSelected("");
      }}
    >
      <Paper
        elevation={1}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          height: 500,
          overflow: "auto",
          padding: 20,
        }}
      >
        {modal && (
          <forms.CommissionForm
            onSubmit={(body) =>
              selected === ""
                ? postCommission({
                    tickerSell: body.get("tickerSell"),
                    tickerBuy: body.get("tickerBuy"),
                    symbol: `${body.get("tickerSell")}_${body.get(
                      "tickerBuy"
                    )}`,
                    commissionPercentSell: +body.get("commissionPercentSell"),
                    commissionPercentBuy: +body.get("commissionPercentBuy"),
                  }).then((resp) => {
                    if (!resp.body.error) {
                      setModal(false);
                    } else {
                      alert("postCommission error");
                      console.log(JSON.stringify(resp.body.data));
                    }
                  })
                : putCommission({
                    ...body.toJS(),
                    commissionId: selected,
                  }).then((resp) => {
                    if (!resp.body.error) {
                      setModal(false);
                      setInitCommission({ ...defaultCommission });
                      setSelected("");
                    } else {
                      alert("putCommission error");
                      console.log(JSON.stringify(resp.body.data));
                    }
                  })
            }
            type={selected === "" ? "create" : "save"}
            currenciesList={currenciesList}
            initialValues={initCommission}
          />
        )}
      </Paper>
    </Modal>
  </div>
);

CommissionScreen.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  commission: PropTypes.object.isRequired,
  postCommission: PropTypes.func.isRequired,
  putCommission: PropTypes.func.isRequired,
  changeStatusCommission: PropTypes.func.isRequired,
  initCommission: PropTypes.object.isRequired,
  setInitCommission: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  deleteCommission: PropTypes.func.isRequired,
  getCommission: PropTypes.func.isRequired,
  currenciesList: PropTypes.array.isRequired,
};

export default compose(
  withState("modal", "setModal", false),
  withState("initCommission", "setInitCommission", { ...defaultCommission }),
  withState("selected", "setSelected", ""),
  apiHOCs.CommissionApiHOC(),
  apiHOCs.CryptocurrencyApiHOC(),
  apiHOCs.FiatApiHOC(),

  withProps(({ fiat, cryptocurrency, commission }) => {
    const currenciesList = [];

    fiat.forEach((F) =>
      currenciesList.push({
        id: F.get("ticker"),
        title: F.get("fullName"),
      })
    );
    cryptocurrency.forEach((C) =>
      currenciesList.push({
        id: C.get("ticker"),
        title: C.get("fullName"),
      })
    );

    const defaultCommissionExist = commission.find(
      (c) => c.get("symbol") === commissions.ALL_ALL
    );
    const cryptoCommissionExist = commission.find(
      (c) => c.get("symbol") === commissions.CRYPTO_CRYPTO
    );

    if (!defaultCommissionExist) {
      currenciesList.unshift({
        id: "CRYPTO",
        title: "CRYPTO",
      });
    }

    if (!defaultCommissionExist) {
      currenciesList.unshift({
        id: "ALL",
        title: "ALL",
      });
    }

    return {
      currenciesList,
    };
  })
)(CommissionScreen);
