import endpoints from "api/endpoints";

export default ({
  id,
  body,
  successCallback
} = {}) => ({
  url: endpoints.getActionExchangeUrl(id),
  queryKey: endpoints.getActionExchangeUrl(id),
  meta: {
    authToken: true,
    successCallback,
  },
  body: body,
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
});
