import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { cryptocurrency } from "schemas";

export default (
  { resultKey = "cryptocurrency" } = { resultKey: "cryptocurrency" }
) => ({
  url: endpoints.getCryptoUrl(),
  transform: (response) =>
    normalize(response.data, cryptocurrency.arrayOfCryptocurrencySchemas)
      .entities,
  transformResult: (response) => ({
    [resultKey]: normalize(
      response.data,
      cryptocurrency.arrayOfCryptocurrencySchemas
    ).result,
  }),
  queryKey: endpoints.getCryptoUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    cryptocurrency: (prevCryptocurrency = Immutable.Map(), crypto) =>
      prevCryptocurrency.mergeDeep(crypto),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => result,
  },
});
