import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import { deal as dealSchema } from "schemas";
import Immutable from "immutable";

export default ({
  resultKey = "shortDisputableDeals",
  page,
  limit,
  adminID,
}) => ({
  url: endpoints.getDisputableDealsUrl({
    finished: false,
    page,
    limit,
    adminID,
  }),
  transform: (response) =>
    normalize(response.data, dealSchema.arrayOfShortDealSchemas).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, dealSchema.arrayOfShortDealSchemas)
      .result,
    [`${resultKey}Meta`]: response.meta,
  }),
  queryKey: endpoints.getDisputableDealsUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    shortDeal: (prevEntities = Immutable.Map(), entities) =>
      prevEntities.mergeDeep(entities),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => result,
    [`${resultKey}Meta`]: (_, result) => result,
  },
});
