import React, { useEffect, useMemo, useState } from "react";
import { compose } from "recompose";
import Paper from "@material-ui/core/Paper";
import { fromJS, List } from "immutable";
import { normalize } from "normalizr";
import * as moment from "moment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableCell, withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import Modal from "@material-ui/core/Modal";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import { apiHOCs, forms } from "../../components";
import { timer as timerSchema } from "../../schemas";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#E1E1E1",
      transition: "background .4s ease",
      cursor: "pointer",
    },
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    width: 800,
  },
  modalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 440,
    height: 150,
    overflow: "auto",
    border: 0,
    "&:focus": {
      outline: "none",
    },
  },
});

const DealTimers = ({
  getDealTimers,
  putDealTimer,
  dealTimers,
  updateDealTimer,
}) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [selectedTimerInfo, setSelectedTimerInfo] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [ timers, setTimers ] = useState(List());

  useEffect(() => {
    getDealTimers((response) => {
      setTimers(fromJS(response.data));
    });
  }, [getDealTimers]);

  const rows = useMemo(() => {
    if (timers.size) {
      const cryptoCryptoTimers = [];
      const fiatCryptoTimers = [];
      const cryptoFiatTimers = [];
      const cashCryptoTimers = [];
      const cryptoCashTimers = [];
      const cashCashTimers = [];

      timers.forEach((t) => {
        if (t.get("alias").includes("CryptoCrypto")) {
          cryptoCryptoTimers.push({
            alias: t.get("alias"),
            duration: t.get("duration"),
          });
        } else if (t.get("alias").includes("CryptoFiat")) {
          cryptoFiatTimers.push({
            alias: t.get("alias"),
            duration: t.get("duration"),
          });
        } else if (t.get("alias").includes("FiatCrypto")) {
          fiatCryptoTimers.push({
            alias: t.get("alias"),
            duration: t.get("duration"),
          });
        } else if (t.get("alias").includes("CashCrypto")) {
          cashCryptoTimers.push({
            alias: t.get("alias"),
            duration: t.get("duration"),
          });
        } else if (t.get("alias").includes("CryptoCash")) {
          cryptoCashTimers.push({
            alias: t.get("alias"),
            duration: t.get("duration"),
          });
        } else if (t.get("alias").includes("CashCash")) {
          cashCashTimers.push({
            alias: t.get("alias"),
            duration: t.get("duration"),
          });
        }
      });
      const timersArray = [
        {
          flow: "CryptoCrypto",
          timers: cryptoCryptoTimers.map((t) => ({
            alias: t.alias,
            duration: t.duration,
          })),
        },
        {
          flow: "CryptoFiat",
          timers: cryptoFiatTimers.map((t) => ({
            alias: t.alias,
            duration: t.duration,
          })),
        },
        {
          flow: "FiatCrypto",
          timers: fiatCryptoTimers.map((t) => ({
            alias: t.alias,
            duration: t.duration,
          })),
        },
        {
          flow: "CryptoCash",
          timers: cryptoCashTimers.map((t) => ({
            alias: t.alias,
            duration: t.duration,
          })),
        },
        {
          flow: "CashCrypto",
          timers: cashCryptoTimers.map((t) => ({
            alias: t.alias,
            duration: t.duration,
          })),
        },
        {
          flow: "CashCash",
          timers: cashCashTimers.map((t) => ({
            alias: t.alias,
            duration: t.duration,
          })),
        },
      ];

      const tableRows = [];
      timersArray.forEach((config) => {
        tableRows.push({
          flowType: config.flow,
          beforeConfirm: config.timers[0].duration,
          beforeAccept: config.timers[1].duration,
          beforeFundsSent: config.timers[2].duration,
          beforeComplete: config.timers[3].duration,
        });
      });
      return tableRows;
    }
  }, [timers]);

  const handlePressTimer = ({ initialValue, flowType, type }) => {
    setSelectedTimerInfo({ initialValue, flowType, type });
    setModal(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Table
          className={classes.table}
          size="medium"
          aria-label="a dense table"
        >
          <TableHead color="dark">
            <TableRow>
              <StyledTableCell>Flow</StyledTableCell>
              <StyledTableCell align="right">
                Before confirm (min)
              </StyledTableCell>
              <StyledTableCell align="right">
                Before accept (min)
              </StyledTableCell>
              <StyledTableCell align="right">
                Before funds sent (min)
              </StyledTableCell>
              <StyledTableCell align="right">
                Before completed (min)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.flowType}
                </TableCell>
                <StyledTableCell
                  onClick={() => {
                    handlePressTimer({
                      initialValue: row.beforeConfirm,
                      flowType: row.flowType,
                      type: "created",
                    });
                  }}
                  align="right"
                >
                  {moment.duration(row.beforeConfirm).asMinutes()}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => {
                    handlePressTimer({
                      initialValue: row.beforeAccept,
                      flowType: row.flowType,
                      type: "confirmed",
                    });
                  }}
                  align="right"
                >
                  {moment.duration(row.beforeAccept).asMinutes()}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => {
                    handlePressTimer({
                      initialValue: row.beforeFundsSent,
                      flowType: row.flowType,
                      type: "accepted",
                    });
                  }}
                  align="right"
                >
                  {moment.duration(row.beforeFundsSent).asMinutes()}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => {
                    handlePressTimer({
                      initialValue: row.beforeComplete,
                      flowType: row.flowType,
                      type: "fundsSent",
                    });
                  }}
                  align="right"
                >
                  {moment.duration(row.beforeComplete).asMinutes()}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <Paper elevation={1} className={classes.modalWrapper}>
          <forms.TimerForm
            onSubmit={(body) => {
              const alias = selectedTimerInfo.type.concat(
                selectedTimerInfo.flowType
              );
              const newTimerInMilliseconds = moment
                .duration(body.get("minutes"), "minutes")
                .asMilliseconds();
              putDealTimer({
                alias,
                body: { duration: newTimerInMilliseconds },
                successCallback: () => {
                  getDealTimers((response) => {
                    const timersData = response.data;
                    setTimers(fromJS(timersData));

                    updateDealTimer(fromJS(timersData));
                    setSnackBarOpen(true);
                    setModal(false);
                  });
                },
              });
            }}
            flowType={selectedTimerInfo.flowType}
            type={selectedTimerInfo.type}
            initialValues={{
              minutes: moment
                .duration(selectedTimerInfo.initialValue)
                .asMinutes(),
            }}
          />
        </Paper>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={4000}
        open={snackBarOpen}
        onClose={() => {
          setSnackBarOpen(false);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={() => {
            setSnackBarOpen(false);
          }}
        >
          Таймер обновлен
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default compose(apiHOCs.DealApiHOC())(DealTimers);
