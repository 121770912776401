import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { user } from "schemas";
import Immutable from "immutable";

export default (requestParams) => ({
  url: endpoints.getUsersUrl(requestParams),
  queryKey: endpoints.getUsersUrl(),
  transform: (response) => normalize(response.data, user.schemasArray).entities,
  transformResult: (response) => ({
    users: normalize(response.data, user.schemasArray).result,
    usersMeta: response.meta,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  },
  update: {
    user: (prevEntities = Immutable.Map(), nextEntities) =>
      prevEntities.mergeDeep(nextEntities),
  },
  updateResult: {
    // если первая страница значит новый запрос (изменён текст поиска)
    // соответственно новые данные
    users: (prevResult = Immutable.List(), result) =>
      requestParams.page === 1 ? result : prevResult.concat(result),
    usersMeta: (prevResult, result) => result,
  },
});
