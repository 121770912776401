import endpoints from "api/endpoints";
import Immutable from "immutable";

export default ({ successCallback }) => ({
  url: endpoints.getLogOutUrl(),
  queryKey: endpoints.getLogOutUrl(),
  meta: {
    authToken: true,
    errorKey: "USER_LOGOUT",
    successCallback,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  },
  update: {
    user: () => Immutable.Map(),
  },
  updateResult: {
    user: () => "",
  },
});
