import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { deal, user } from "schemas";

export const getDisputableDeals = createSelector(
  (state, key) => getResults(state).get(key, Immutable.List()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, deal.arrayOfDealSchemas, entities)
);

export const getShortDisputableDeals = createSelector(
  (state, key) => getResults(state).get(key, Immutable.List()),
  (state) => getEntities(state),
  (result, entities) =>
    denormalize(result, deal.arrayOfShortDealSchemas, entities)
);

export const getDisputableDealsMeta = (state, key) =>
  getResults(state).get(key, Immutable.Map());

export const getUser = createSelector(
  (state) => getResults(state).get("user", Immutable.Map()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, user.schema, entities)
);

export const getDeal = createSelector(
  (state) => getResults(state).get("deal", Immutable.Map()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, deal.dealSchema, entities)
);
