import React, { useMemo } from "react";
import {
  compose,
  getContext,
  withState,
  lifecycle
  // withProps,
} from "recompose";
import { apiHOCs } from "components";
import PropTypes from "prop-types";
import { token } from "helpers";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

import "./style.scss";
import { Badge } from "@material-ui/core";

const NavBar = ({
  anchorEl,
  setAnchor,
  user,
  isDrawerOpen,
  setDrawerOpen,
  router,
  postLogOut,
  shortDisputableDeals,
  tickets
}) => {
  const handleLogOutClick = () =>
    postLogOut({
      successCallback: () => {
        token.removeToken();
      },
    });

    const openTickets = useMemo(() => {
      return tickets.filter((ticket) => {
        return !ticket.get('adminID');
      });
    }, [tickets]);  
  return (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <AppBar position="static" color="default">
        <Toolbar>
          <IconButton
            style={{
              marginLeft: -12,
              marginRight: 20,
            }}
            color="inherit"
            aria-label="Menu"
            onClick={() => setDrawerOpen(!isDrawerOpen)}
          >
            <Badge
              badgeContent={shortDisputableDeals.size || openTickets.size}
              color="secondary"
              overlap="rectangle"
              variant="dot"
            >
              <MenuIcon />
            </Badge>
          </IconButton>
          <Typography
            variant="title"
            color="inherit"
            style={{
              flexGrow: 1,
            }}
          >
            Site Admin
          </Typography>
          <div>
            <Button
              aria-owns={anchorEl ? "menu-appbar" : null}
              aria-haspopup="true"
              onClick={(e) => setAnchor(e.currentTarget)}
            >
              <AccountCircle style={{ marginRight: 10 }} />
              {user.get("name")}
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchor(null)}
            >
              <MenuItem onClick={() => setAnchor(null)}>My account</MenuItem>
              <MenuItem onClick={handleLogOutClick}>Log out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
        <div tabIndex={0} role="button" onClick={() => setDrawerOpen(false)}>
          <div
            style={{
              width: 350,
            }}
          >
            <List>
              <div>
                <ListItem button onClick={() => router.history.push("/")}>
                  <Badge
                    badgeContent={shortDisputableDeals.size}
                    color="secondary"
                    overlap="circle"
                  >
                    <ListItemText
                      style={{ paddingRight: 20 }}
                      primary="Disputes"
                    />
                  </Badge>
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/commissions")}
                >
                  <ListItemText primary="Comissions" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/private-chat")}
                >
                  <Badge
                    badgeContent={openTickets.size}
                    color="secondary"
                    overlap="circle"
                  >
                    <ListItemText style={{ paddingRight: 20 }} primary="Private Chats" />
                  </Badge>
                </ListItem>
                <ListItem button onClick={() => router.history.push("/users")}>
                  <ListItemText primary="Users" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/cryptocurrency")}
                >
                  <ListItemText primary="Cryptocurrency" />
                </ListItem>
                <ListItem button onClick={() => router.history.push("/fiat")}>
                  <ListItemText primary="Fiat" />
                </ListItem>
                <ListItem button onClick={() => router.history.push("/bank")}>
                  <ListItemText primary="Bank" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/payment")}
                >
                  <ListItemText primary="Payment System" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/exchange")}
                >
                  <ListItemText primary="Exchange" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/deals-and-adv")}
                >
                  <ListItemText primary="Deals & Adv" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/deal-timers")}
                >
                  <ListItemText primary="Deal Timers" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/profit-percent")}
                >
                  <ListItemText primary="Profit percent" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => router.history.push("/suspicious")}
                >
                  <ListItemText primary="Suspicious users" />
                </ListItem>
              </div>
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

NavBar.propTypes = {
  anchorEl: PropTypes.element,
  // accessRights: PropTypes.array,
  setAnchor: PropTypes.func,
  user: PropTypes.object,
  isDrawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  router: PropTypes.object,
  postLogOut: PropTypes.func,
};

export default compose(
  apiHOCs.AuthApiHOC(),
  apiHOCs.ChatApiHOC(),
  apiHOCs.DisputableDealsApiHOC(),

  withState("anchorEl", "setAnchor", null),
  withState("isDrawerOpen", "setDrawerOpen", false),

  lifecycle({
    componentDidMount() {
      this.props.getTickets({closed: false});
    },
  }),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  })
)(NavBar);
