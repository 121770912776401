import React from "react";
import PropTypes from "prop-types";
import { Field, FieldArray, reduxForm } from "redux-form/immutable";
import { required } from "validators";
import { ui } from "components";
import Button from "@material-ui/core/Button";

const DealAndOfferForm = ({ handleSubmit }) => (
  <form className="auth-form" autoComplete="off" onSubmit={handleSubmit}>
    <Field
      component={ui.Fields.BaseField}
      name="id"
      props={{
        id: "id",
        label: "offer/deal id",
        fullWidth: true,
      }}
      validate={[required]}
    />
    <Button
      variant="outlined"
      type="submit"
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      SEARCH
    </Button>
  </form>
);

DealAndOfferForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "dealAndOfferForm",
})(DealAndOfferForm);
