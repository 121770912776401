import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import { deal as dealSchema } from "schemas";
import Immutable from "immutable";

export default (type) => ({
  url: endpoints.getDisputableDealsWithTypeUrl(type),
  transform: (response) =>
    normalize(response.data, dealSchema.arrayOfDealSchemas).entities,
  transformResult: (response) => ({
    [`${type}DisputableDeals`]: normalize(
      response.data,
      dealSchema.arrayOfDealSchemas
    ).result,
  }),
  queryKey: endpoints.getDisputableDealsWithTypeUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    deal: (prevEntities = Immutable.Map(), entities) =>
      prevEntities.mergeDeep(entities),
  },
  updateResult: {
    [`${type}DisputableDeals`]: (prevResult, result) => result,
    deals: (prevResult, result) => result,
  },
});
