import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { commission } from "schemas";

export default (
  { resultKey = "commission", commissionId } = { resultKey: "commission" }
) => ({
  url: endpoints.getChangeStatusCommissionUrl(commissionId),
  transform: (response) =>
    normalize(response.data, commission.commissionSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, commission.commissionSchema).result,
  }),
  queryKey: endpoints.getChangeStatusCommissionUrl(commissionId),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    commission: (prevCommission = Immutable.Map(), result) =>
      prevCommission.mergeDeep(result),
  },
});
