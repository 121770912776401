import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import { compose, getContext, withState } from "recompose";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TableSkeleton from "../../components/ui/Skeletons/TableSkeleton";
import StickyHeadTable from "../../components/ui/StickyHeadTable";
import moment from "moment";

const DisputableDealsScreen = ({
  onChangeOpenDisputesCount,
  getDeal,
  shortDisputableDealsMeta: meta,
  shortDisputableDeals,
  disputableDeals,
  getDisputableDealsIsFetching,
  putConfirmAdmin,
  getShortDisputableDeals,
  user,
}) => {
  useEffect(() => {
    getShortDisputableDeals({
      page: 1,
      limit: 10,
    });
  }, []);

  useEffect(() => {
    onChangeOpenDisputesCount(shortDisputableDeals.size);
  }, [shortDisputableDeals.size]);

  const handleChangePage = (event, newPage) => {
    getShortDisputableDeals({
      limit: 10,
      page: newPage + 1,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          marginBottom: 20,
          padding: 10,
        }}
      >
        <Typography
          variant="headline"
          component="h3"
          style={{
            textAlign: "center",
          }}
        >
          Open disputes
        </Typography>
      </Paper>
      {getDisputableDealsIsFetching ? (
        <TableSkeleton width={820} />
      ) : (
        <Paper
          elevation={1}
          style={{
            width: 820,
          }}
        >
          <StickyHeadTable
            deals={disputableDeals}
            onChangePage={handleChangePage}
            showDealInfo={(id) => {
              getDeal({ id, resultKey: "disputableDeals" });
            }}
            columns={[
              { id: "number", label: "#" },
              { id: "id", label: "ID" },
              { id: "createdAt", label: "Дата создания" },
              { id: "setOnMe", label: "" },
            ]}
            rows={shortDisputableDeals
              .sort(
                (prev, next) =>
                  new Date(next.get("createdAt")) -
                  new Date(prev.get("createdAt"))
              )
              .map((deal) => ({
                number: deal.get("serialNumber"),
                id: deal.get("id"),
                createdAt: moment(deal.get("createdAt")).format(
                  "MMMM Do YYYY, h:mm:ss a"
                ),
                setOnMe: (
                  <Button
                    variant="contained"
                    onClick={() => putConfirmAdmin({ id: deal.get("id") })}
                  >
                    Set on me
                  </Button>
                ),
              }))}
            meta={meta}
          />
        </Paper>
      )}
    </div>
  );
};

DisputableDealsScreen.propTypes = {
  disputableDeals: PropTypes.object,
  putConfirmAdmin: PropTypes.func,
  router: PropTypes.any,
  onChangeOpenDisputesCount: PropTypes.func.isRequired,
};

export default compose(
  apiHOCs.DisputableDealsApiHOC(),
  apiHOCs.AuthApiHOC(),

  getContext({
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  })
)(DisputableDealsScreen);
