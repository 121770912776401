import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { message } from "schemas";
import Immutable from "immutable";

export default ({ chatID, requestBody, successCallback }) => ({
  url: endpoints.getSystemMessageUrl(chatID),
  queryKey: endpoints.getSystemMessageUrl(),
  transform: (response) => normalize(response.data, message.schema).entities,
  transformResult: (response) => ({
    messages: normalize(response.data, message.schema).result,
  }),
  body: requestBody,
  meta: {
    authToken: true,
    successCallback,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  },
  update: {
    message: (prevEntities = Immutable.Map(), entities) =>
      prevEntities.mergeDeep(entities),
  },
  updateResult: {
    messages: (prevResult, result) => prevResult.push(result),
  },
});
