import React from "react";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";
import { ChromePicker } from "react-color";

import "./style.scss";

const ColorField = ({
  input: { value, onChange },
  displayColorPicker,
  setDisplayColorPicker,
  label,
}) => (
  <div className="color-picker">
    <button
      type="button"
      onClick={() => setDisplayColorPicker(!displayColorPicker)}
      className="color-picker__button"
      style={{
        border: `solid 2px ${value}`,
      }}
    >
      {label}
    </button>
    {displayColorPicker ? ( //eslint-disable-line
      <div className="color-picker__popover">
        <div
          className="color-picker__popover__cover"
          onClick={() => setDisplayColorPicker(false)}
        />
        <ChromePicker color={value} onChange={({ hex }) => onChange(hex)} />
      </div>
    ) : null}
  </div>
);

ColorField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  displayColorPicker: PropTypes.bool.isRequired,
  setDisplayColorPicker: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default compose(
  withState("displayColorPicker", "setDisplayColorPicker", false)
)(ColorField);
