import endpoints from "api/endpoints";
import Immutable from "immutable";

export default ({ alias, body, successCallback }) => ({
  url: endpoints.getPutDeaTimerUrl(alias),
  queryKey: endpoints.getSetAdminUrl(),
  meta: {
    authToken: true,
    successCallback,
  },
  body,
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
});
