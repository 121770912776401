import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { fiat as fiatSchemas } from "schemas";

export default (
  {
    resultKey = "fiat",
    alias,
    shortName,
    fullName,
    ticker,
    minLimit,
    maxLimit,
    step,
    digits,
  } = { resultKey: "fiat" }
) => ({
  url: endpoints.getFiatUrl(),
  transform: (response) =>
    normalize(response.data, fiatSchemas.fiatSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, fiatSchemas.fiatSchema).result,
  }),
  queryKey: endpoints.getFiatUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  body: {
    alias,
    shortName,
    fullName,
    ticker,
    minLimit,
    maxLimit,
    step,
    digits,
  },
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
  },
  update: {
    fiat: (prevFiat = Immutable.Map(), fiat) => prevFiat.mergeDeep(fiat),
  },
  updateResult: {
    [resultKey]: (prevResult, result) => prevResult.push(result),
  },
});
