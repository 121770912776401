import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form/immutable";
import { ui } from "components";
import Immutable from "immutable";
import Button from "@material-ui/core/Button";

import "./style.scss";

const ConstructorField = ({ fields }) => (
  <div className="constructor-field">
    <Button
      variant="contained"
      color="primary"
      onClick={() => fields.push(Immutable.Map())}
    >
      Add Field
    </Button>
    <div className="constructor-field__fields-list">
      {fields.map((field, index) => (
        <div
          key={index} //eslint-disable-line
          className="constructor-field__fields-list__field"
        >
          <Field
            name={`${field}.alias`}
            component={ui.Fields.BaseField}
            props={{
              id: `${field}.name`,
              label: "field name",
              style: {
                width: "30%",
              },
              fullWidth: true,
            }}
          />
          <Field
            name={`${field}.name`}
            component={ui.Fields.BaseField}
            props={{
              id: `${field}.alias`,
              label: "eng",
              style: {
                width: "80%",
              },
              fullWidth: true,
            }}
          />
          <Field
            name={`${field}.ru_alias`}
            component={ui.Fields.BaseField}
            props={{
              id: `${field}.ru_alias`,
              label: "rus",
              style: {
                width: "80%",
              },
              fullWidth: true,
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => fields.remove(index)}
            style={{
              fontSize: 12,
              height: 36,
            }}
          >
            Remove Field
          </Button>
        </div>
      ))}
    </div>
  </div>
);

ConstructorField.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default ConstructorField;
