import { requestAsync } from '@digitalwing.co/redux-query-immutable';
import endpoints from 'api/endpoints';

/**
 *
 * @typedef GetAesKey
 * @property key {String}
 * @property id {String}
 *
 * @param {GetAesKey} queryParams
 */
export default ({ id, key }) => requestAsync({
  url: endpoints.getAesKeyUrl(id, key),
  queryKey: endpoints.getAesKeyUrl(),
  meta: {
    authToken: true,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
  },
});