import { requestAsync } from '@digitalwing.co/redux-query-immutable';
import endpoints from 'api/endpoints';
import { normalize } from 'normalizr';
import { chat, message } from 'schemas';
import { Map } from 'immutable';
import { parseJson } from 'helpers';

/**
 *
 * @param id {String}
 * @param successCallback {Function}
 */
export default ({ id, successCallback }) => requestAsync({
  url: endpoints.getChatUrl(id),
  queryKey: endpoints.getChatUrl(id),
  transform: response =>
    normalize({
 ...response.data,
messages: response.data.messages.map(item =>
      ({
        ...item,
        files: item.files ? item.files.filter(file => file).map(parseJson) : [],
      })),
}, chat.schema).entities,
  transformResult: response => ({
    chat: normalize(response.data, chat.schema).result,
    messages: normalize(response.data.messages, message.schemasArray).result,
  }),
  meta: {
    authToken: true,
    successCallback,
  },
  options: {
    headers: {
      Accept: 'application/json',
    },
    method: 'GET',
  },
  update: {
    message: (prevEntities = Map(), entities) =>
      prevEntities.merge(entities),
  },
  updateResult: {
    messages: (prevResult, result) => result,
  },
});
