import getPayment from "./getPayment";
import postPayment from "./postPayment";
import putPayment from "./putPayment";
import changeStatusPayment from "./changeStatusPayment";
import deletePayment from "./deletePayment";

export default {
  getPayment,
  postPayment,
  putPayment,
  changeStatusPayment,
  deletePayment,
};
