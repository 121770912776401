import getShortDisputableDeals from "./getShortDisputableDeals";
import getShortActiveDisputableDeals from "./getShortActiveDisputableDeals";
import getShortClosedDisputableDeals from "./getShortClosedDisputableDeals";
import getDisputableDealsWithType from "./getDisputableDealsWithType";
import putConfirmAdmin from "./putConfirmAdmin";
import putResolveDeal from "./putResolveDeal";
import getDeal from "./getDeal";
import getDealTimers from "./getDealTimers";
import putDealTimer from "./putDealTimer";

export default {
  getShortDisputableDeals,
  getShortActiveDisputableDeals,
  getShortClosedDisputableDeals,
  putConfirmAdmin,
  putResolveDeal,
  getDisputableDealsWithType,
  getDeal,
  getDealTimers,
  putDealTimer,
};
