import React from "react";
import PropTypes from "prop-types";
import { compose, withHandlers } from "recompose";

import endpoints from "api/endpoints";
import { getToken } from "helpers/token";

import "./style.scss";

const ImageField = ({ input: { value }, uploadImage, label }) => (
  <div className="image-picker">
    <input
      id="file"
      type="file"
      onChange={uploadImage}
      className="image-picker__input"
    />
    <label htmlFor="file">{`Choose a ${label}`}</label>
    {value && (
      <img
        src={value}
        height="200"
        alt="icon"
        className="image-picker__image"
      />
    )}
  </div>
);

ImageField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  uploadImage: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default compose(
  withHandlers({
    uploadImage: ({ input: { onChange } }) => async (e) => {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(endpoints.getUploadingBucketUrl("payment"), {
        body: formData,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
      });

      const data = await response.json();

      onChange(`${endpoints.getFileBucketUrl("payment")}/${data.urls[0]}`);
    },
  })
)(ImageField);
