import React from "react";
import { Skeleton } from "@material-ui/lab";

function TableSkeleton({ width }) {
  return (
    <>
      <Skeleton
        variant="rect"
        width={width}
        height={50}
        animation="wave"
        style={{ marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        width={width}
        height={30}
        animation="wave"
        style={{ marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        width={width}
        height={30}
        animation="wave"
        style={{ marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        width={width}
        height={30}
        animation="wave"
        style={{ marginBottom: 15 }}
      />
      <Skeleton
        variant="rect"
        width={width}
        height={30}
        animation="wave"
        style={{ marginBottom: 15 }}
      />
    </>
  );
}

export default TableSkeleton;
