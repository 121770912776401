import endpoints from "../endpoints";

const postPaymentFieldTranslations = async function ({
  paymentName,
  kit,
  i18n,
}) {
  let nodes = [];
  const nodePrefix = "HOME.PAGE_PAYMENTS.UI_PAYMENT_SYSTEM.LABELS.CUSTOM";
  kit.forEach((k) => {
    nodes.push({
      node: `${nodePrefix}.${paymentName.toUpperCase()}.${k.name.toUpperCase()}`,
      en: k.alias,
      ru: k.ruAlias,
    });
  });

  const url = endpoints.getPostTranslationsUrl();
  const options = {
    stringify: (data) => JSON.stringify(data),
  };

  const callback = (err) => {
    if (err) throw new Error(err);
  };

  //запрос в сервис транслейтов
  i18n.options.backend.request(options, url, { nodes }, callback);
};

export default postPaymentFieldTranslations;
