import React from "react";
import { apiHOCs, ui } from "components";
import { branch, compose, renderComponent, withProps } from "recompose";
import { Redirect, Route, Switch } from "react-router";
import * as screens from "screens";

import "./style.scss";

const HomeScreen = () => (
  <div className="home-layout">
    <ui.NavBar />
    <Switch>
      <Route path="/commissions" component={screens.CommissionScreen} />
      <Route path="/chats/:id" component={screens.ChatScreen} />
      <Route path="/users" component={screens.UsersScreen} />
      <Route path="/deal/:id" component={screens.DealScreen} />
      <Route path="/cryptocurrency" component={screens.CryptocurrencyScreen} />
      <Route path="/exchange" component={screens.ExchangeScreen} />
      <Route path="/fiat" component={screens.FiatScreen} />
      <Route path="/bank" component={screens.BankScreen} />
      <Route path="/payment" component={screens.PaymentSystemScreen} />
      <Route path="/deals-and-adv" component={screens.DealsAndAdvScreen} />
      <Route path="/deal-timers" component={screens.DealTimers} />
      <Route path="/profit-percent" component={screens.ProfitPercentScreen} />
      <Route path="/suspicious" component={screens.SuspiciousScreen} />
      <Route path="/private-chat" component={screens.PrivateChatsScreen} />
      <Route path="/" component={screens.DisputesScreen} />
    </Switch>
  </div>
);

const redirectComponent = withProps({ to: "/signin" })(Redirect);

export default compose(
  apiHOCs.AuthApiHOC(),

  branch(
    ({ user }) => !(user && user.size && user.get("role") === "admin"),
    renderComponent(redirectComponent)
  )
)(HomeScreen);
