import React, { useCallback, useEffect, useState } from "react";
import { compose } from "recompose";
import { reset } from "redux-form";
import Paper from "@material-ui/core/Paper";

import { apiHOCs } from "../../components";
import DealAndOfferForm from "../../components/forms/DealAndOfferForm";
import OfferInfoBlock from "../../components/ui/OfferInfoBlock";
import DealInfoBlock from "../../components/ui/DealInfoBlock";

const DealsAndAdvScreen = ({
  deal,
  getDeal,
  resetDeal,
  offer,
  getOffer,
  resetOffer,
  stopOffer,
  startOffer,
  deleteOffer,
  resetForm,
}) => {
  const [selectedID, setSelectedID] = useState();

  const handleSearchDealsAndAdv = (values) => {
    const searchedID = values.get("id");

    setSelectedID(searchedID);
    getDeal({
      id: searchedID,
      successCallback: async () => {
        resetOffer();
      },
      errorCallback: async () => {
        resetDeal();

        //если сделки с таким ID не существует, делаем запрос оффера с таким ID
        getOffer({
          id: values.get("id"),
          errorCallback: async () => {
            resetOffer();
          },
        });
      },
    });
  };

  const handleStopOffer = useCallback((id) => {
    if (id) {
      stopOffer({ id });
    }
  }, []);
  const handleStartOffer = useCallback((id) => {
    if (id) {
      startOffer({ id });
    }
  }, []);
  const handleDeleteOffer = useCallback((id) => {
    if (id) {
      deleteOffer({ id });
      resetForm();
    }
  }, []);

  useEffect(() => {
    resetOffer();
    resetDeal();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={1}
        style={{
          width: 800,
          padding: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <DealAndOfferForm onSubmit={handleSearchDealsAndAdv} />
        {offer && offer.size > 0 && (
          <OfferInfoBlock
            offer={offer}
            stopOffer={handleStopOffer}
            deleteOffer={handleDeleteOffer}
            startOffer={handleStartOffer}
          />
        )}
        {deal.size > 0 && <DealInfoBlock deal={deal} />}
      </Paper>
    </div>
  );
};

export default compose(
  apiHOCs.DealApiHOC(),
  apiHOCs.OfferApiHOC()
)(DealsAndAdvScreen);
