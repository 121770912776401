import { bindActionCreators } from "redux";
import { compose, lifecycle } from "recompose";
import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { fiat as fiatApi } from "api";

import { getFiat } from "./selectors";

const FiatApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        fiat: getFiat(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getFiat: () => requestAsync(fiatApi.getFiat()),
            postFiat: (body) => requestAsync(fiatApi.postFiat({ ...body })),
            putFiat: (body) => requestAsync(fiatApi.putFiat({ ...body })),
            deleteFiat: (fiatId) =>
              requestAsync(fiatApi.deleteFiat({ fiatId })),
          },
          dispatch
        ),
      })
    ),
    lifecycle({
      componentDidMount() {
        this.props.getFiat();
      },
    })
  )(WrappedComponent);

export default FiatApiHOC;
