import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { user, suspect } from "schemas";

export const getUsers = createSelector(
  (state) => getResults(state).get("users", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, user.schemasArray, entities)
);

export const getSuspicious = createSelector(
  (state) => getResults(state).get("suspects", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) => denormalize(result, suspect.schemasArray, entities)
)

export const getUsersMeta = (state) =>
  getResults(state).get("usersMeta", Immutable.Map());
