import { createSelector } from "reselect";
import { getEntities, getResults } from "reducers";
import Immutable from "immutable";
import { denormalize } from "normalizr";
import { cryptocurrency } from "schemas";

export const getCrypto = createSelector(
  (state) => getResults(state).get("cryptocurrency", Immutable.List()),
  (state) => getEntities(state),
  (result, entities) =>
    denormalize(result, cryptocurrency.arrayOfCryptocurrencySchemas, entities)
);
