import { unpack } from './utils';

/**
 * Функция для дешифровки aes ключа от бека
 *
 * @param strKey {string} Строка с ключем в base64
 * @param privateKey {CryptoKey} Вектор инициализации в base64
 * @return {Promise<ArrayBuffer>} Строку с дешифрованым сообщением
 */
export default async (strKey, privateKey) => {
    const cipher = unpack(strKey);

    const aesKey = await window.crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
      },
      privateKey,
      cipher,
    );

    return aesKey;
};
