import endpoints from "api/endpoints";

export default ({ body, successCallback }) => {
  return {
    url: endpoints.getPutProfitLimitsUrl(),
    queryKey: endpoints.getPutProfitLimitsUrl(),
    meta: {
      authToken: true,
      successCallback,
    },
    body,
    options: {
      headers: {
        Accept: "application/json",
      },
      method: "PUT",
    },
  };
};
