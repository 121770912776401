import endpoints from "api/endpoints";
import { normalize } from "normalizr";
import { message } from "schemas";
import Immutable from "immutable";

export default ({ id }) => ({
  url: endpoints.getMessagesUrl(id),
  queryKey: endpoints.getMessagesUrl(),
  transform: (response) =>
    normalize(response.data, message.schemasArray).entities,
  transformResult: (response) => ({
    messages: normalize(response.data, message.schemasArray).result,
  }),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  },
  update: {
    message: (prevEntities = Immutable.Map(), entities) =>
      prevEntities.mergeDeep(entities),
  },
  updateResult: {
    messages: (prevResult, result) => result,
  },
});
