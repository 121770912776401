import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { exchange } from "schemas";

export default ({ resultKey = "exchange" } = {}) => ({
  url: endpoints.getExchangeUrl(),
  transform: (response) =>
    normalize(response.data, exchange.arrayOfExchangeSchemas).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, exchange.arrayOfExchangeSchemas)
      .result,
  }),
  queryKey: endpoints.getExchangeUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
  },
  update: {
    exchange: (_, nextExchange = Immutable.Map()) => nextExchange,
  },
  updateResult: {
    [resultKey]: (_, result = Immutable.List()) => result,
  },
});
