import { bindActionCreators } from "redux";
import { compose, lifecycle } from "recompose";
import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { exchange as exchangeApi } from "api";

import { getExchange } from "./selectors";

const ExchangeApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        exchange: getExchange(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getExchange: () => requestAsync(exchangeApi.getExchange()),
            getRefreshExchanges: (body) =>
              requestAsync(exchangeApi.getRefreshExchanges({ ...body })),
            putExchange: (body) =>
              requestAsync(exchangeApi.putExchange({ ...body })),
            deleteExchange: (body) =>
              requestAsync(exchangeApi.deleteExchange({ ...body })),
          },
          dispatch
        ),
      })
    ),
    lifecycle({
      componentDidMount() {
        this.props.getExchange();
      },
    })
  )(WrappedComponent);

export default ExchangeApiHOC;
