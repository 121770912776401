import { bindActionCreators } from "redux";
import { compose, lifecycle } from "recompose";
import { requestAsync } from "@digitalwing.co/redux-query-immutable";
import { connect } from "react-redux";
import { commission as commissionApi } from "api";

import { getCommission } from "./selectors";

const CommissionApiHOC = () => (WrappedComponent) =>
  compose(
    connect(
      (state) => ({
        commission: getCommission(state),
      }),
      (dispatch) => ({
        ...bindActionCreators(
          {
            getCommission: () => requestAsync(commissionApi.getCommission()),
            postCommission: (body) =>
              requestAsync(commissionApi.postCommission({ ...body })),
            putCommission: (body) =>
              requestAsync(commissionApi.putCommission({ ...body })),
            changeStatusCommission: (commissionId) =>
              requestAsync(
                commissionApi.changeStatusCommission({ commissionId })
              ),
            deleteCommission: (commissionId) =>
              requestAsync(commissionApi.deleteCommission({ commissionId })),
          },
          dispatch
        ),
      })
    ),
    lifecycle({
      componentDidMount() {
        this.props.getCommission();
      },
    })
  )(WrappedComponent);

export default CommissionApiHOC;
