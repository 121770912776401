import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { bank as bankSchemas } from "schemas";

export default (
  {
    resultKey = "bank",
    bankId,
    alias,
    shortName,
    fullName,
    englishName,
    colorBg,
    color,
    fiats,
    icon,
    countries,
  } = { resultKey: "bank" }
) => ({
  url: endpoints.getActionBankUrl(bankId),
  transform: (response) =>
    normalize(response.data, bankSchemas.bankSchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, bankSchemas.bankSchema).result,
  }),
  queryKey: endpoints.getActionBankUrl(bankId),
  meta: {
    authToken: true,
  },
  force: true,
  body: {
    alias,
    shortName,
    fullName,
    englishName,
    colorBg,
    color,
    fiats,
    icon,
    countries,
  },
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    bank: (prevBank = Immutable.Map(), bank) => prevBank.mergeDeep(bank),
  },
});
