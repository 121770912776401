import React from "react";
import PropTypes from "prop-types";
import { apiHOCs } from "components";
import { compose, lifecycle, withProps } from "recompose";
import _ from "lodash";
import moment from "moment";
import Immutable from "immutable";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const DealScreen = ({ id, deal }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Paper
      elevation={1}
      style={{
        width: 800,
        padding: 10,
        marginBottom: 20,
      }}
    >
      <Typography
        variant="headline"
        component="h3"
        style={{
          textAlign: "center",
        }}
      >
        {`DEAL ${id}`}
      </Typography>
    </Paper>
    <Paper
      elevation={1}
      style={{
        width: 820,
      }}
    >
      {deal.size > 0 && ( //eslint-disable-line
        <List>
          <ListItem key={1}>
            <ListItemText
              primary="Seller name:"
              secondary={`${deal.get("seller").get("username")}`}
            />
            <ListItemText
              primary="Buyer name:"
              secondary={`${deal.get("buyer").get("username")}`}
            />
          </ListItem>
          <ListItem key={2}>
            <ListItemText
              primary="Seller amount:"
              secondary={`${deal.get("amountSellerSends")}`}
            />
            <ListItemText
              primary="Buyer amount:"
              secondary={`${deal.get("amountBuyerGets")}`}
            />
          </ListItem>
          <ListItem key={3}>
            <ListItemText
              primary="Sell ticker:"
              secondary={`${deal.get("tickerSell")}`}
            />
            <ListItemText
              primary="Buy ticker:"
              secondary={`${deal.get("tickerBuy")}`}
            />
          </ListItem>
          <ListItem key={4}>
            <ListItemText
              primary={`Rate (${deal.get("stockExchange")}):`}
              secondary={`${deal.get("saleRate")}`}
            />
          </ListItem>
          <ListItem key={5}>
            <ListItemText
              primary="Created At:"
              secondary={`${moment(deal.get("createdAt")).format(
                "MMMM Do YYYY, HH:mm:ss"
              )}`}
            />
          </ListItem>
        </List>
      )}
    </Paper>
  </div>
);

DealScreen.propTypes = {
  id: PropTypes.string.isRequired,
  deal: PropTypes.object.isRequired,
};

export default compose(
  apiHOCs.DisputableDealsApiHOC(),

  withProps((props) => ({
    id: _.get(props, "match.params.id"),
    deal:
      _.get(props, "closedDisputableDeals", {}).find(
        (item) => item.get("id") === _.get(props, "match.params.id")
      ) || Immutable.Map(),
  })),

  lifecycle({
    componentDidMount() {
      this.props.getDisputableDealsWithType();
    },
  })
)(DealScreen);
