import endpoints from "api/endpoints";

export default ({ id, role, resultKey = "shortActiveDisputableDeals" }) => ({
  url: endpoints.getResolveDealUrl({ dealId: id, role }),
  queryKey: endpoints.getResolveDealUrl(),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: () => {},
  updateResult: {
    [resultKey]: (prevResult) => prevResult.filter((item) => item !== id),
  },
});
