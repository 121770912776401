import { normalize } from "normalizr";
import endpoints from "api/endpoints";
import Immutable from "immutable";
import { cryptocurrency } from "schemas";

export default (
  { resultKey = "cryptocurrency", cryptoId } = { resultKey: "cryptocurrency" }
) => ({
  url: endpoints.getChangeStatusCryptoUrl(cryptoId),
  transform: (response) =>
    normalize(response.data, cryptocurrency.cryptocurrencySchema).entities,
  transformResult: (response) => ({
    [resultKey]: normalize(response.data, cryptocurrency.cryptocurrencySchema)
      .result,
  }),
  queryKey: endpoints.getChangeStatusCryptoUrl(cryptoId),
  meta: {
    authToken: true,
  },
  force: true,
  options: {
    headers: {
      Accept: "application/json",
    },
    method: "PUT",
  },
  update: {
    cryptocurrency: (prevCryptocurrency = Immutable.Map(), crypto) =>
      prevCryptocurrency.mergeDeep(crypto),
  },
});
